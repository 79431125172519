/**
 * @ComponentFor CheckoutPageViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { addUserLog, get, pathCombine, translate, updateAppShellData } from '@avensia/scope';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import InteractivePaymentGatewayAdapter, { ChangeType } from './interactive-payment-gateway-adapter';
import { Main, Form, Appearance as PageAppearance, Basic, Part } from 'Shared/PageLayout';
import { connectWithFeedback, ConnectPropType as FeedbackPropType } from 'Shared/Button/Feedback';
import FullCart from '../../FullCart';
import Summary from '../../Summary';
import CompletePurchase from '../../CompletePurchase';
import ShippingMethods from '../../Shipping/ShippingMethods';
import OrdererForm from '../../Order/OrdererForm';
import {
  setSubscribeNewsLetter,
  setShippingMethod,
  setShippingDeliveryPoint,
  setPaymentMethod,
  completePurchase,
  setOrderer,
  sendPendingCheckoutRequests,
  addDiscountCode,
  removeDiscountCode,
  reloadCart,
  setIsIngridUpdate,
  setIngridShippingPrice,
  setShippingMethodUpdate
} from '../../action-creators';
import CheckoutType from './CheckoutPageViewModel.type';
import CheckoutUpdateResultType from './CheckoutUpdateResult.type';
import { CartType } from 'Shared/State';
import connect from 'Shared/connect';
import OrdererType from '../../Order/OrdererViewModel.type';
import { getPaymentMethodId, getShippingMethodId } from '../../saved-customer-info';
import {
  theta,
  minMediumMediaQuery,
  pixelsToUnit,
  minLargeMediaQuery,
  minSmallMediaQuery,
  minTinyMediaQuery,
  minHugeMediaQuery,
  small,
  gamma,
  huge,
  delta,
  alpha,
  thin,
  monochromeDark,
  whitelilac,
  white,
  transition,
} from 'Shared/Style';
import InteractiveGatewaySnippetAndCapabilitiesViewModel from './InteractiveGatewaySnippetAndCapabilitiesViewModel.type';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import DeliveryPoint from '../../Shipping/DeliveryPointViewModel.type';
import ProductsPanel from 'Shared/ProductsPanel';
import Viewport from 'Shared/Viewport';
import { CodeInput, CodeList } from 'Checkout/DiscountCodes';
import Promotions from 'Checkout/Promotions';
import ProductCard from 'Checkout/ProductsPanel/Item';
import ProductCardMobile from 'Checkout/ProductsPanel/ItemMobile';
import { getProduct as getPanelProduct } from 'Checkout/ProductsPanel/utils';
import NewsLetter from 'Checkout/NewsLetter';
import Cover from './Cover';
import SectionDivider from './SectionDivider';
import CheckoutPageUpdateViewModel from './CheckoutPageUpdateViewModel.type';
import DesignTagProperties from 'Cart/Models/DesignTagProperties.type';
import { Style } from '@glitz/type';
import { Up, Down } from 'Shared/Icon/Arrow';
import IngridWidget, { ingridApi } from './IngridWidget';
import { checkoutPageUrl } from 'Shared/known-urls';


enum LockMode {
  Unlocked,
  LockedGatewayDisabled,
  LockedGatewayEnabled,
}

type ConnectStateType = {
  cart: CartType;
};

type ConnectActionType = {
  setSubscribeNewsLetter: (didSubscribe: boolean) => Promise<void>;
  setShippingMethod: (id: string) => Promise<void>;
  setPaymentMethod: (id: string) => Promise<void>;
  completePurchase: (modelCreator: () => CheckoutPageUpdateViewModel) => Promise<void>;
  setShippingDeliveryPoint: (deliveryPoint: DeliveryPoint) => Promise<void>;
  setOrderer: (orderer: OrdererType) => Promise<void>;
  sendPendingCheckoutRequests: () => Promise<void>;
  addDiscountCode: (code: string) => Promise<CheckoutUpdateResultType>;
  removeDiscountCode: (code: string) => Promise<void>;
  updateAppShellData: () => Promise<void>;
  reloadCart: () => Promise<void>;
  setIngridShippingPrice: (price: number) => Promise<void>
  setIsIngridUpdate: (isIngridUpdate: boolean) => Promise<void>
  setShippingMethodUpdate: (id: string) => Promise<void>;
};

type PropType = CheckoutType &
  ConnectStateType &
  ConnectActionType & {
    orderer: OrdererType;
    onChangeOrderer: (orderer: OrdererType) => void;
  };

type StateType = {
  interactiveGatewayInfo: InteractiveGatewaySnippetAndCapabilitiesViewModel;
  lockMode: LockMode;
  lockVisibleElement: HTMLElement;
  showAllItemsToShowcase: boolean;
  hasOutofStockItem: boolean;
};

class CheckoutPage extends React.Component<PropType & FeedbackPropType, StateType> {
  formElement: HTMLFormElement;
  intervalId: any;
  paymentRef = React.createRef<HTMLDivElement>();
  ingridSuspended: boolean;
  invalidListener: () => void;
  interactiveGateway: InteractivePaymentGatewayAdapter;
  constructor(props: PropType & FeedbackPropType) {
    super(props);
    this.intervalId = null;
    this.state = {
      interactiveGatewayInfo: props.interactiveGatewayInfo,
      lockMode: LockMode.Unlocked,
      lockVisibleElement: null,
      showAllItemsToShowcase: false,
      hasOutofStockItem: props.cart.hasOutofStockItem,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropType & FeedbackPropType) {
    if (nextProps.interactiveGatewayInfo) {
      this.setState({ interactiveGatewayInfo: nextProps.interactiveGatewayInfo });
    }
  }

  componentDidMount() {
    if (this.formElement) {
      this.invalidListener = () => {
        addUserLog('Tried to submit invalid checkout form');
      };
      this.formElement.addEventListener('invalid', this.invalidListener, true);
    }
    const timeUntilReload = this.props.checkoutPageReloadTimeoutInMilliseconds;
    if (timeUntilReload > 0) {
      let result: boolean;
      this.intervalId = setInterval(() => {
        get(pathCombine(checkoutPageUrl(), 'hasoutofstockitem'))
          .then(res => res.json())
          .then(r => result = r);
        if (result) {
          if (this.props.cart.hasOutofStockItem != result) {
            this.setState({
              hasOutofStockItem: result
            });
            window.location.reload();
          }
        }
      }, timeUntilReload);
    }
  }

  componentDidUpdate() {
    if (this.ingridSuspended && !this.props.isLoading) {
      ingridApi(api => {
        this.ingridSuspended = false;
        api.resume();
      });
    }
  }

  componentWillUnmount() {
    if (this.formElement && this.invalidListener) {
      this.formElement.removeEventListener('invalid', this.invalidListener, true);
      this.formElement = null;
      this.invalidListener = null;
      clearInterval(this.intervalId);
    }

  }

  UNSAFE_componentWillMount() {
    const previousPaymentMethodId = getPaymentMethodId();
    if (
      !this.props.hasExplicitlySelectedPaymentMethod &&
      this.props.leftToPay > 0 &&
      previousPaymentMethodId &&
      this.props.paymentMethods.find((p) => p.id === previousPaymentMethodId)
    ) {
      this.onSelectPaymentMethod(previousPaymentMethodId);
    }

    const previousShippingMethodId = getShippingMethodId();
    if (
      !this.props.hasExplicitlySelectedShippingMethod &&
      previousShippingMethodId &&
      this.props.shippingMethods.find((s) => s.id === previousShippingMethodId)
    ) {
      this.onSelectShippingMethod(previousShippingMethodId);
    }
  }
  onLockInput = (visibleElement: HTMLElement, keepGatewayEnabled: boolean) => {
    ingridApi(api => { api.suspend(); });
    this.setState({
      lockMode: keepGatewayEnabled ? LockMode.LockedGatewayEnabled : LockMode.LockedGatewayDisabled,
      lockVisibleElement: visibleElement,
    });
  };
  onUnlockInput = () => {
    ingridApi(api => { api.resume(); });
    this.setState({ lockMode: LockMode.Unlocked, lockVisibleElement: null });
  };
  toggleItemsToShowcase = () => {
    this.setState({
      showAllItemsToShowcase: !this.state.showAllItemsToShowcase,
    });
  };
  onChangeOrderer = (orderer: OrdererType) => {
    this.props.setOrderer(orderer);
  };
  onSelectShippingMethod = (id: any) => {
    this.props.setShippingMethod(id);
  };
  onSelectShippingMethodUpdate = (id: any) => {
    this.props.setShippingMethodUpdate(id);
  };
  onDataChanged = (external_method_id: string, price: number) => {
    const shippingMethod = this.props.shippingMethods.find((s) => s.type === external_method_id);
    if (!!shippingMethod) {
      this.onSelectShippingMethodUpdate(shippingMethod.id);
      this.props.setIsIngridUpdate(true);
      this.props.setIngridShippingPrice(price);
    }
  };
  onAddressChanged = (postal_code: string) => {
    if (!postal_code) {
      return;
    }
    const currentOrderer = this.props.orderer;
    const newOrderer = {
      ...currentOrderer,
      alternativeDeliveryAddress: {
        ...currentOrderer.alternativeDeliveryAddress,
        postalCode: postal_code,
      },
    };
    this.props.setIsIngridUpdate(true);
    this.onChangeOrderer(newOrderer);
  };
  onSelectPaymentMethod(id: string) {
    this.props.setPaymentMethod(id);
  };
  onSelectSetIngridPrice(price: number) {
    this.props.setIngridShippingPrice(price);
  };
  onInfoChanged = (changeType: ChangeType) => {
    const updatedOrderer = {
      email: changeType.email,
      mobileNumber: changeType.mobileNumber,
      personalNumber: changeType.personalNumber,
      billingAddress: {
        firstName: changeType.address?.firstName,
        city: changeType.address?.city,
        lastName: changeType.address?.lastName,
        address1: changeType.address?.street,
        address2: null,
        postalCode: changeType.address?.postalCode,
        phoneNumber: changeType.mobileNumber,
        email: changeType.email,
        // doorCode: null, //TODO: these need fixes
        // additionalDeliveryInformation: null,
        houseNumber: null,
      },
      alternativeDeliveryAddress: this.props.orderer.alternativeDeliveryAddress,
      hasAlternativeDeliveryAddress: this.props.orderer.hasAlternativeDeliveryAddress,
      phoneNumber: changeType.mobileNumber,
      emailHashed: this.props.orderer.emailHashed,
      hasEnoughInfoToCompletePurchase: this.props.orderer.hasEnoughInfoToCompletePurchase,
    } as OrdererType;

    if (!updatedOrderer.billingAddress?.postalCode) {
      updatedOrderer.billingAddress.postalCode = changeType.postalCode;
      updatedOrderer.billingAddress.firstName = changeType.firstName;
      updatedOrderer.billingAddress.lastName = changeType.lastName;
    }

    this.props.setOrderer(updatedOrderer);
  };

  onFormKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    // Prevents form submission when the enter key is pressed in a input field
    // Only targets inputs as submit button should submit and textares should be able to add newlines.
    if (
      (e.target as HTMLElement).tagName.toLowerCase() === 'input' &&
      (e.keyCode || e.which || e.charCode || e.key || 0) === 13
    ) {
      e.preventDefault();
    }
  };

  onCompletePurchase = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.interactiveGateway) {
      this.props.feedback.push(this.interactiveGateway.completePurchase(this.props.leftToPay));
    } else {
      const cartItems: {
        [item: string]: {
          previousQuantity: number;
          newQuantity: number;
          stockLevelExceeded: boolean;
          designTagProperties: DesignTagProperties;
        };
      } = {};
      this.props.cart.items.forEach(
        (i) =>
        (cartItems[i.code] = {
          newQuantity: i.quantity,
          previousQuantity: 0,
          stockLevelExceeded: false,
          designTagProperties: { colorName: ' ', text: '', color: '', font: '' },
        }),
      );
      this.props.feedback.push(
        this.props.completePurchase(() => {
          return {
            cart: { items: cartItems, isCheckoutPage: true },
            orderer: this.props.orderer,
            selectedShippingMethodId: this.props.selectedShippingMethodId,
            selectedPaymentMethodId: this.props.selectedPaymentMethodId,
            selectedDeliveryPoint: this.props.selectedDeliveryPoint,
            addedDiscountCodes: this.props.discountCodes,
            removedDiscountCodes: [],
            subscribeToNewsLetter: this.props.subscribeToNewsLetter,
            nameMobile: this.props.selectedDeliveryPoint.nameMobile,
            priceFromIngrid: null,
            isIngridUpdate: false,
          };
        }),
      );
    }
  };

  onToggleSubscription = () => this.props.setSubscribeNewsLetter(!this.props.subscribeToNewsLetter);

  onPaymentFailed = (msgs: string[]) => {
    console.error('Interactive checkout payment failed', msgs);
    this.props.updateAppShellData();
  };

  render() {
    const promotions = this.props.promotions || [];
    const itemsToShowCase = this.props.itemsToShowCase || [];
    const productMostPopular = this.props.productMostPopular || [];

    const discountCodes = this.props.discountCodes || [];
    const { discountCodeText, addDiscountCodeText, invalidPromoCodeMessage } = this.props.page;
    const { showAllItemsToShowcase } = this.state;
    const itemsToShowcaseMobileCardHeight = 90;
    const discountCodeNode =
      discountCodes.length > 0 ? (
        <CodeList codeText={discountCodeText} codes={discountCodes} removeCode={this.props.removeDiscountCode} />
      ) : (
        <CodeInput
          title={epiPropertyValue(addDiscountCodeText)}
          addCode={this.props.addDiscountCode}
          errorMessage={epiPropertyValue(invalidPromoCodeMessage)}
        />
      );

    const itemsToShowcaseFull: Style = {
      ...transition({ property: 'all', duration: '2s', timingFunction: 'ease' }),
      overflow: 'hidden',
      height: `calc(${pixelsToUnit(itemsToShowcaseMobileCardHeight * itemsToShowCase.length)})`,
    };

    const itemsToShowcaseHalf: Style = {
      ...transition({ property: 'all', duration: '0.8s', timingFunction: 'ease' }),
      height: `calc(${pixelsToUnit(itemsToShowcaseMobileCardHeight * 3)})`,
      overflow: 'hidden',
    };

    return this.props.cart.items.length === 0 ? (
      <Basic appearance={PageAppearance.Full}>
        <MainOnEmptyCart>
          <Section css={{ textAlign: 'center', backgroundColor: white }}>
            {!!epiPropertyValue(this.props.page.heading) && (
              <Header>
                <EpiProperty component={PageTitle} for={this.props.page.heading} />
              </Header>
            )}
            <LightBody css={{ padding: { y: small } }}>{epiPropertyValue(this.props.page.emptyCartText)}</LightBody>
          </Section>

          {productMostPopular.length > 0 && (
            <React.Fragment>
              <SectionDivider innerColor={white} sideColor={'transparent'} />
              <DarkSection>
                <Header>
                  <Title>{translate('/Checkout/Header/RecommendedBestSellers')}</Title>
                </Header>
                <Body>
                  <Viewport>
                    {(isCompact: boolean) => (
                      <ProductsPanel
                        navigationOffsetWidth={CARD_WIDTH * (isCompact ? MOBILE_NUM_OF_CARDS : DESKTOP_NUM_OF_CARDS)}
                        slideQuantityToShow={isCompact ? MOBILE_NUM_OF_CARDS : DESKTOP_NUM_OF_CARDS}
                      >
                        {productMostPopular.map((product) => (
                          <ProductCard
                            key={epiPropertyValue(product.variation.code)}
                            product={getPanelProduct(product)}
                          />
                        ))}
                      </ProductsPanel>
                    )}
                  </Viewport>
                </Body>
              </DarkSection>
              <SectionDivider innerColor={white} sideColor={'transparent'} />
            </React.Fragment>
          )}
        </MainOnEmptyCart>
        <EmptyCartBelowSection>
          {epiPropertyValue(this.props.page.emptyCartButtonLink) && (
            <Part
              css={{
                textAlign: 'center',
                fontSize: 0,
              }}
            >
              <ShowCampaigns
                to={epiPropertyValue(this.props.page.emptyCartButtonLink).url}
                appearance={ButtonAppearance.Secondary}
              >
                {epiPropertyValue(this.props.page.emptyCartButtonText)}
              </ShowCampaigns>
            </Part>
          )}
          <EpiProperty for={this.props.page.emptyCartArea} />
        </EmptyCartBelowSection>
      </Basic>
    ) : (
      <MainOnFullCart>
        {this.state.lockMode !== LockMode.Unlocked && (
          <Cover
            css={{
              height: pixelsToUnit(this.paymentRef.current.offsetTop),
              position: 'absolute',
            }}
            visibleElement={this.state.lockVisibleElement}
          />
        )}
        <CheckoutForm
          elementRef={(el: HTMLFormElement) => (this.formElement = el)}
          onSubmit={this.onCompletePurchase}
          onKeyPress={this.onFormKeyPress}
        >
          <FullCartSection>
            <FullCartHeader
              css={{
                justifyContent: epiPropertyValue(this.props.page.heading) ? 'space-between' : 'flex-end',
              }}
            >
              {!!epiPropertyValue(this.props.page.heading) && (
                <EpiProperty component={PageTitle} for={this.props.page.heading} />
              )}
              {discountCodeNode}
            </FullCartHeader>
            <FullCartBody>
              <FullCart
                tableHeadings={{
                  productName: epiPropertyValue(this.props.page.productLabel),
                  quantity: epiPropertyValue(this.props.page.quantityLabel),
                  price: epiPropertyValue(this.props.page.priceLabel),
                  unitPrice: epiPropertyValue(this.props.page.unitPriceLabel),
                  totalPrice: epiPropertyValue(this.props.page.totalPriceLabel),
                }}
                cart={this.props.cart}
                isLoading={this.props.cart.isLoading}
              />

              <FullCartLower>
                {promotions.length > 0 && (
                  <Promotions
                    activatedText={epiPropertyValue(this.props.page.activatedPromotionsText)}
                    css={{
                      borderBottom: {
                        width: thin,
                        style: 'solid',
                        color: monochromeDark,
                      },
                      marginTop: small,
                      paddingBottom: small,
                      width: '100%',
                      [minMediumMediaQuery]: {
                        borderBottomStyle: 'none',
                        flexBasis: '65%',
                        marginTop: huge,
                        paddingBottom: 0,
                      },
                    }}
                    promotions={promotions}
                  />
                )}

                <Summary
                  css={{
                    marginTop: small,
                    width: '100%',
                    [minMediumMediaQuery]: {
                      marginTop: huge,
                      flexBasis: '35%',
                    },
                  }}
                  totalPriceText={this.props.page.totalCartPriceText}
                  totalPrice={this.props.leftToPay}
                  totalSavings={this.props.cart.totalSavings}
                  totalDiscountText={this.props.page.totalDiscountText}
                  paymentFeeText={this.props.page.paymentFeeText}
                  paymentFee={this.props.paymentFee}
                  shippingFeeText={this.props.page.shippingFeeText}
                  shippingFee={this.props.shippingFee}
                  currency={this.props.cart.currency}
                  isLoading={this.props.cart.isLoading}
                />
              </FullCartLower>
            </FullCartBody>
          </FullCartSection>

          {itemsToShowCase.length > 0 && (
            <React.Fragment>
              <SectionDivider innerColor={whitelilac} sideColor={white} />
              <WhiteSection>
                {!!epiPropertyValue(this.props.page.itemsToShowcaseTitle) && (
                  <Header>
                    <EpiProperty component={Title} for={this.props.page.itemsToShowcaseTitle} />
                  </Header>
                )}
                <Body>
                  <Viewport>
                    {(isCompact: boolean) => (
                      <React.Fragment>
                        {isCompact ? (
                          <ProductsPanelMobile css={showAllItemsToShowcase ? itemsToShowcaseFull : itemsToShowcaseHalf}>
                            {showAllItemsToShowcase ? (
                              <Hide onClick={this.toggleItemsToShowcase}>
                                {translate('/Checkout/Header/Hide')}
                                <UpStyled />
                              </Hide>
                            ) : (
                              <ShowMore onClick={this.toggleItemsToShowcase}>
                                {translate('/Checkout/Header/ShowMore')} <DownStyled />
                              </ShowMore>
                            )}

                            {itemsToShowCase.map((product) => (
                              <ProductCardMobile
                                key={epiPropertyValue(product.variation.code)}
                                product={getPanelProduct(product)}
                              />
                            ))}
                            <GradientBar />
                          </ProductsPanelMobile>
                        ) : (
                          <ProductsPanel
                            navigationOffsetWidth={
                              CARD_WIDTH * (isCompact ? MOBILE_NUM_OF_CARDS : DESKTOP_NUM_OF_CARDS)
                            }
                            slideQuantityToShow={
                              isCompact ? MOBILE_PRODUCT_PANEL_NUM_OF_CARDS : DESKTOP_PRODUCT_PANEL_NUM_OF_CARDS
                            }
                          >
                            {itemsToShowCase.map((product) => (
                              <ProductCard
                                key={epiPropertyValue(product.variation.code)}
                                product={getPanelProduct(product)}
                              />
                            ))}
                          </ProductsPanel>
                        )}
                      </React.Fragment>
                    )}
                  </Viewport>
                </Body>
              </WhiteSection>
              <SectionDivider innerColor={white} sideColor={whitelilac} />
            </React.Fragment>
          )}

          <NewsLetter
            subscribe={this.props.subscribeToNewsLetter}
            toggleSubscription={this.onToggleSubscription}
            heading={this.props.newsletterHeading}
            description={this.props.newsletterDescription}
            note={this.props.newsletterCheckboxMessage}
          />

          <PaymentHead>
            <PaymentHeadTextContainer>
              <PaymentHeader> {epiPropertyValue(this.props.page.paymentShippingHeader)}</PaymentHeader>
              <PaymentHeadText>{epiPropertyValue(this.props.page.paymentShippingInfoText)}</PaymentHeadText>
            </PaymentHeadTextContainer>
          </PaymentHead>
          <IngridContainer>
            <IngridWidget
              widgetHtml={this.props.ingridHtmlSnippet}
              sessionId={this.props.ingridSessionId}
              onDataChanged={this.onDataChanged}
              onAddressChanged={this.onAddressChanged}
            />
          </IngridContainer>
          <Payment ref={this.paymentRef}>
            {!(this.state.interactiveGatewayInfo && this.state.interactiveGatewayInfo.hasShippingMethodSelection) && (
              <Section>
                {!!epiPropertyValue(this.props.page.shippingsMethodsHeader) && (
                  <Header>
                    <EpiProperty component={Title} for={this.props.page.shippingsMethodsHeader} />
                  </Header>
                )}
                <Body>
                  <ShippingMethods
                    css={{ textAlign: 'left' }}
                    shippingMethods={this.props.shippingMethods}
                    currency={this.props.cart.currency}
                    onSelect={this.onSelectShippingMethod}
                    // tslint:disable-next-line:jsx-no-lambda
                    onSelectDeliveryPoint={(id: DeliveryPoint) => this.props.setShippingDeliveryPoint(id)}
                    selectedDeliveryPoint={this.props.selectedDeliveryPoint}
                    selectedMethodId={this.props.selectedShippingMethodId}
                    heading={this.props.page.shippingsMethodsHeader}
                  />
                </Body>
              </Section>
            )}
            <Section>
              <Body>
                {!(this.state.interactiveGatewayInfo && this.state.interactiveGatewayInfo.hasAddressInput) && (
                  <OrdererForm
                    page={this.props.page}
                    orderer={this.props.orderer}
                    onChange={this.onChangeOrderer}
                    send={this.props.sendPendingCheckoutRequests}
                  />
                )}
                {(this.state.interactiveGatewayInfo && (!this.props.cart.hasOutofStockItem && !this.state.hasOutofStockItem)) ? (
                  <InteractivePaymentGatewayAdapter
                    snippet={this.state.interactiveGatewayInfo.snippet}
                    isLoading={this.props.isLoading}
                    onInfoChanged={this.onInfoChanged}
                    onPaymentFailed={this.onPaymentFailed}
                    email={this.props.orderer.email}
                    ref={(c) => (this.interactiveGateway = c)}
                    onLockInput={this.onLockInput}
                    onUnlockInput={this.onUnlockInput}
                    reloadCart={this.props.reloadCart}
                  />
                ) : (<div>{translate('/Cart/OutOfStockWarning')}</div>)}

              </Body>
            </Section>
            {!(this.state.interactiveGatewayInfo && this.state.interactiveGatewayInfo.hasBuyButton) && (
              <Section>
                <Body>
                  <CompletePurchase
                    currency={this.props.cart.currency}
                    FeedbackButton={this.props.feedback.Button}
                    checkoutPage={this.props.page}
                    isLoading={this.props.cart.isLoading}
                    totalPrice={this.props.leftToPay}
                    completePurchaseText={this.props.page.completePurchaseText}
                    paymentFeeText={this.props.page.paymentFeeText}
                    paymentFee={this.props.paymentFee}
                    shippingFeeText={this.props.page.shippingFeeText}
                    shippingFee={this.props.shippingFee}
                    serverValidationErrors={this.props.validationResult}
                    somethingWasUpdatedDuringCompletePurchase={this.props.somethingWasUpdatedDuringCompletePurchase}
                    heading={this.props.page.completePurchaseHeader}
                    paymentErrorMessage={this.props.paymentErrorMessage}
                  />
                  {(this.state.interactiveGatewayInfo && (!this.props.cart.hasOutofStockItem && !this.state.hasOutofStockItem)) ? (
                    <InteractivePaymentGatewayAdapterWrapper>
                      <InteractivePaymentGatewayAdapter
                        snippet={this.state.interactiveGatewayInfo.snippet}
                        isLoading={this.props.isLoading}
                        onPaymentFailed={this.onPaymentFailed}
                        onInfoChanged={this.onInfoChanged}
                        onLockInput={this.onLockInput}
                        onUnlockInput={this.onUnlockInput}
                        reloadCart={this.props.reloadCart}
                        email={this.props.orderer.email}
                        ref={(c) => (this.interactiveGateway = c)}
                      />
                    </InteractivePaymentGatewayAdapterWrapper>
                  ) : (<div>{translate('/Cart/OutOfStockWarning')}</div>)}
                </Body>
              </Section>
            )}
          </Payment>
        </CheckoutForm>
      </MainOnFullCart>
    );
  }
}
export default connect(
  (state): ConnectStateType => ({
    cart: state.cart,
  }),
  (dispatch): ConnectActionType => ({
    updateAppShellData() {
      return dispatch(updateAppShellData());
    },
    setSubscribeNewsLetter(didSubscribe: boolean) {
      return dispatch(setSubscribeNewsLetter(didSubscribe));
    },
    setShippingMethod(id: string) {
      return dispatch(setShippingMethod(id));
    },
    setShippingMethodUpdate(id: string) {
      return dispatch(setShippingMethodUpdate(id));
    },
    setShippingDeliveryPoint(deliveryPoint: DeliveryPoint) {
      return dispatch(setShippingDeliveryPoint(deliveryPoint));
    },
    setPaymentMethod(id: string) {
      return dispatch(setPaymentMethod(id));
    },
    completePurchase(modelCreator: () => CheckoutPageUpdateViewModel) {
      return dispatch(completePurchase(modelCreator));
    },
    setOrderer(orderer: OrdererType) {
      return dispatch(setOrderer(orderer));
    },
    sendPendingCheckoutRequests() {
      return dispatch(sendPendingCheckoutRequests());
    },
    addDiscountCode(code: string) {
      return dispatch(addDiscountCode(code));
    },
    removeDiscountCode(code: string) {
      return dispatch(removeDiscountCode(code));
    },
    reloadCart() {
      return dispatch(reloadCart());
    },
    setIsIngridUpdate(isIngridUpdate: boolean) {
      return dispatch(setIsIngridUpdate(isIngridUpdate));
    },
    setIngridShippingPrice(price: number) {
      return dispatch(setIngridShippingPrice(price));
    },
  }),
)(
  connectWithFeedback({
    minimumPending: 750,
    maximumRejected: 500,
    maximumFulfilled: 500,
  })(CheckoutPage),
);

const CARD_WIDTH = 152;
const DESKTOP_NUM_OF_CARDS = 5;
const DESKTOP_PRODUCT_PANEL_NUM_OF_CARDS = 4;
const MOBILE_NUM_OF_CARDS = 2;
const MOBILE_PRODUCT_PANEL_NUM_OF_CARDS = 3;
const CART_PADDING_PER_SIDE = 430;
const EMPTY_CART_BELOW_PADDING_PER_SIDE = 30;

const baseStyled = styled({
  padding: {
    y: 0,
  },
});

const MainOnFullCart = baseStyled(Main);

const MainOnEmptyCart = baseStyled(Main, {
  marginTop: huge,
  marginBottom: huge,
  [minTinyMediaQuery]: {
    width: '87.5%',
  },
  [minSmallMediaQuery]: {
    width: '75%',
  },
  [minMediumMediaQuery]: {
    width: '65%',
  },
  [minLargeMediaQuery]: {
    width: '55%',
  },
  [minHugeMediaQuery]: {
    width: `calc(100% - ${pixelsToUnit(CART_PADDING_PER_SIDE * 2)})`,
  },
});

const CheckoutForm = styled(Form, {
  margin: {
    x: 'auto',
  },
  width: '100%',
  [minTinyMediaQuery]: {
    width: '87.5%',
  },
  [minSmallMediaQuery]: {
    width: '75%',
  },
  [minMediumMediaQuery]: {
    width: pixelsToUnit(800),
  },
});

const lightStyled = styled({
  backgroundColor: white,
});

const darkStyled = styled({
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  backgroundColor: white,
  marginTop: '1rem',
  position: 'relative',
  paddingBottom: pixelsToUnit(20),
  [minMediumMediaQuery]: {
    paddingTop: pixelsToUnit(30),
    paddingBottom: pixelsToUnit(40),
  },
});

const whiteStyled = styled({
  backgroundColor: white,
  padding: { x: small },
});

const Section = styled.section({
  display: 'flex',
  flexDirection: 'column',
});

const FullCartSection = styled(Section, {
  paddingBottom: 0,
  [minMediumMediaQuery]: {
    paddingBottom: 0,
  },
});

const DarkSection = darkStyled(Section);

const WhiteSection = whiteStyled(Section);

const Header = styled.header({
  marginBottom: small,
  padding: {
    x: small,
  },
  [minMediumMediaQuery]: {
    padding: {
      x: pixelsToUnit(30),
    },
  },
});

const FullCartHeader = styled(Header, {
  display: 'flex',
  marginTop: pixelsToUnit(50),
  paddingTop: pixelsToUnit(50),
  [minMediumMediaQuery]: {
    display: 'block',
    marginBottom: pixelsToUnit(30),
    padding: { xy: 0 },
    position: 'relative',
  },
});

const Body = styled.div({
  backgroundColor: white,
  [minMediumMediaQuery]: {
    padding: {
      x: small,
      y: '2rem',
    },
  },
});

const LightBody = lightStyled(Body);

const FullCartBody = styled(LightBody, {
  padding: {
    top: small,
    x: small,
  },

  [minLargeMediaQuery]: {
    padding: {
      xy: pixelsToUnit(30),
    },
  },
});

const FullCartLower = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  [minMediumMediaQuery]: {
    flexDirection: 'row',
  },
});

const PageTitle = styled.h1({
  font: {
    size: gamma,
    weight: 'bold',
  },
  lineHeight: 'normal',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: pixelsToUnit(38),
    textAlign: 'center',
  },
});

const Title = styled.h2({
  font: {
    size: delta,
    weight: 'normal',
  },
  lineHeight: pixelsToUnit(23),
  textAlign: 'center',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: alpha,
    lineHeight: pixelsToUnit(42),
  },
});

const InteractivePaymentGatewayAdapterWrapper = styled.div({
  position: 'relative',
});

const Payment = styled.div({
  padding: { xy: pixelsToUnit(10) },
  backgroundColor: white,
});

const EmptyCartBelowSection = styled(Part, {
  margin: {
    x: 'auto',
  },
  width: '100%',
  [minTinyMediaQuery]: {
    width: `calc(100% - ${pixelsToUnit(EMPTY_CART_BELOW_PADDING_PER_SIDE * 2)})`,
  },
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(110),
  },
});

const ShowCampaigns = styled(Button, {
  fontSize: theta,
  letterSpacing: pixelsToUnit(0.8),
  padding: {
    x: small,
  },
});

const ProductsPanelMobile = styled.div({
  position: 'relative',
});

const GradientBar = styled.div({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '30px',
  backgroundImage: `linear-gradient(180deg, rgba(255,0,0,0), rgba(255,255,255,1))`,
});

const ShowMore = styled.div({
  width: '100%',
  textAlign: 'center',
  fontSize: pixelsToUnit(10),
  marginBottom: pixelsToUnit(10),
});

const Hide = styled(ShowMore, {});

const UpStyled = styled(Up, {
  marginLeft: pixelsToUnit(5),
  opacity: 0.5,
  fontSize: pixelsToUnit(10),
  paddingBottom: pixelsToUnit(3),
});

const DownStyled = styled(Down, {
  marginLeft: pixelsToUnit(10),
  opacity: 0.5,
  fontSize: pixelsToUnit(10),
  paddingTop: pixelsToUnit(3),
});

const PaymentHead = styled.div({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  marginTop: pixelsToUnit(20),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: { xy: pixelsToUnit(10) },
});

const PaymentHeadTextContainer = styled.div({
  maxWidth: '500px',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const PaymentHeader = styled.div({
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '10px',
});

const PaymentHeadText = styled.div({});

const IngridContainer = styled.div({
  marginBottom: pixelsToUnit(10),
});
