import React from 'react';
import { styled } from '@glitz/react';
import { alto, carnation, thin, transition, pixelsToUnit } from 'Shared/Style';

type PropType = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

type StateType = {
  invalid: boolean;
};

const Field = styled.div({
  width: '100%',
  textAlign: 'center',
});

const Label = styled.label({
  display: 'block',
  marginBottom: pixelsToUnit(10),
});

const Input = styled.input({
  backgroundImage: 'none',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: alto,
    },
  },
  color: 'inherit',
  display: 'inline-block',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  margin: {
    xy: 0,
  },
  width: '100%',
  textAlign: 'center',
  flexGrow: 1,
  minWidth: '20ch',
  WebkitAppearance: 'textfield',
  ...transition({ property: 'width' }),
});

class FieldWithValidation extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      invalid: false,
    };
  }

  validateFields(e: React.FormEvent<HTMLInputElement>) {
    const el = e.target as HTMLInputElement;
    const invalid = !el.validity.valid;
    if (invalid !== this.state.invalid) {
      this.setState({ invalid });
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    this.validateFields(e);
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.validateFields(e);
  };

  render() {
    const { label, required, ...restProps } = this.props;
    return (
      <Field>
        <Label css={this.state.invalid && { color: carnation }}>
          {label}
          {required && ' *'}
        </Label>
        <Input {...restProps} onBlur={this.onBlur} onChange={this.onChange} />
      </Field>
    );
  }
}

export default FieldWithValidation;
