import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import Ul from 'Shared/Generic/Ul';
import ShippingMethodType from './ShippingMethodViewModel.type';
import ShippingMethodRow from './ShippingMethodRow';
import DeliveryPoint from './DeliveryPointViewModel.type';

type PropType = {
  shippingMethods: ShippingMethodType[];
  heading: Scope.Property<string>;
  onSelect: (id: string) => void;
  selectedDeliveryPoint: DeliveryPoint;
  onSelectDeliveryPoint: (deliveryPoint: DeliveryPoint) => any;
  selectedMethodId: string;
  currency: string;
};

function ShippingMethods(props: PropType & StyledProps) {
  const { shippingMethods, ...restProps } = props;
  return (
    <Ul css={props.compose()} column>
      {shippingMethods.map((method, index) => (
        <ShippingMethodRow key={method.id} method={method} {...restProps} />
      ))}
    </Ul>
  );
}

export default styled(ShippingMethods);
