import React from 'react';
import { styled } from '@glitz/react';
import { EpiProperty } from '@avensia/scope-episerver';
import { minMediumMediaQuery } from 'Shared/Style';
import RemoveIcon from 'Shared/Icon/Close';

type PropType = {
  codeText?: Scope.Property<string>;
  codes: string[];
  removeCode: (code: string) => Promise<void>;
};

type StateType = {
  removing: boolean;
};

const Base = styled.div({
  marginBottom: '5px',
  [minMediumMediaQuery]: {
    marginBottom: 0,
    position: 'absolute',
    right: 0,
    top: '40px',
  },
});

const Remove = styled.span({
  cursor: 'pointer',
  marginLeft: '5px',
});

class CodeList extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      removing: false,
    };
    this.remove = this.remove.bind(this);
  }

  async remove(code: string) {
    try {
      this.setState({
        removing: true,
      });
      await this.props.removeCode(code);
    } catch (error) {
      this.setState({
        removing: false,
      });
      console.error('Removing discount code error', error);
      Promise.reject(error);
    }
  }

  render() {
    return (
      <Base css={{ opacity: this.state.removing ? 0.4 : 1 }}>
        {this.props.codes.map(code => (
          <span key={code}>
            <EpiProperty component="span" for={this.props.codeText} args={{ code }} />
            <Remove onClick={() => this.remove(code)}>
              <RemoveIcon />
            </Remove>
          </span>
        ))}
      </Base>
    );
  }
}

export default CodeList;
