import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { translate } from '@avensia/scope';
import Price, { Type as PriceType } from 'Pricing/Price';
import Image, { Ratio, Preset } from 'Shared/Image/Ratio';
import {
  gamma,
  zeta,
  pixelsToUnit,
  whitelilac,
  minTinyMediaQuery,
  minSmallMediaQuery,
  kappa,
  wildwatermelon,
  white,
  black,
  delta,
} from 'Shared/Style';
import connect from 'Shared/connect';
import Button, { Variant } from 'Shared/Button';
import { formatTitleCase } from 'Shared/string-format';
import Link from 'Shared/Link';
import AddToCart from 'Product/ProductAddToCart';
import AverageRating, { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import PriceViewModel from 'Pricing/PriceViewModel.type';
import { addCartItem } from 'Checkout/action-creators';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import Icon from 'Shared/Icon/Cart';
import InfoIcon from 'Shared/Icon/Info';

type ConnectStateType = {
  items: CartItemViewModel[];
};

type ConnectActionType = {
  addToCart: (code: string, quantity: number, ticket: string) => any;
};

export type ItemType = {
  brand: string;
  averageRating: number;
  ticket: string;
  price: PriceViewModel;
  displayName: string;
  variantCode: string;
  imageUrls: string[];
  isNew: boolean;
  inStock: boolean;
  isUpsellModal?: boolean;
  hasPromotion: boolean;
  hasDiscount: boolean;
  url: string;
  hasMultipleVariants: boolean;
};

type PropType = {
  product: ItemType;
} & ConnectStateType &
  ConnectActionType &
  StyledProps;

class Item extends React.Component<PropType> {
  addToCart = () => {
    const code = this.props.product.variantCode;
    const existingItem = this.props.items.find((i) => i.code === code && !i.isGift);
    const quantity = existingItem ? existingItem.quantity + 1 : 1;

    return this.props.addToCart(code, quantity, this.props.product.ticket);
  };

  render() {
    const {
      hasPromotion,
      hasDiscount: productHasDiscount,
      isNew,
      averageRating,
      imageUrls,
      displayName,
      price,
      brand,
      url,
      ticket,
      isUpsellModal,
      hasMultipleVariants,
    } = this.props.product;
    const { current, original } = price;
    const hasDiscount = current < original;

    const cardMobileProps = {
      brand,
      currency: price.currency,
      displayName,
      productHasDiscount,
      hasDiscount,
      current,
      original,
      currentPrice: price.current,
      upsell: price.upsell,
      hasPromotion,
      isNew,
      isUpsellModal,
      averageRating,
      imageUrl: imageUrls.length ? imageUrls[0] : '',
      price,
    };

    const button = hasMultipleVariants ? (
      <Button
        css={{
          height: pixelsToUnit(40),
          width: pixelsToUnit(40),
          borderRadius: '100%',
          backgroundColor: (theme) => theme.buyButtonProductcardMobile,
        }}
        to={url}
        variant={Variant.None}
      >
        <InfoIconStyled />
      </Button>
    ) : (
      <AddToCartStyled variant={Variant.None} disabled={!this.props.product.inStock} addToCart={this.addToCart}>
        <CartIcon />
      </AddToCartStyled>
    );

    return (
      <Base css={this.props.compose()}>
        <CardWrapper>
          <ContentLink to={url} linkIdentifier={ticket}>
            <CardMobile {...cardMobileProps} />
          </ContentLink>
          <ButtonWrap>{button}</ButtonWrap>
        </CardWrapper>
      </Base>
    );
  }
}

type CardMobilePropType = {
  brand: string;
  currency: string;
  displayName: string;
  productHasDiscount: boolean;
  hasDiscount: boolean;
  current: number;
  original: number;
  currentPrice: number;
  upsell: number;
  hasPromotion: boolean;
  isNew: boolean;
  averageRating: number;
  imageUrl: string;
  price: PriceViewModel;
};

export function CardMobile(props: CardMobilePropType) {
  const discount = props.original - props.currentPrice;
  return (
    <React.Fragment>
      <Title title={props.displayName} itemProp="name">{`${props.brand || ''} ${formatTitleCase(
        props.displayName,
      )}`}</Title>
      <CardOuterWrap>
        <RatingImageWrap>
          <ImageWrap>
            <Image
              src={props.imageUrl}
              alt={props.displayName}
              itemProp="image"
              preset={!props.imageUrl.includes('.gif') ? Preset.Tiny : undefined}
              ratio={Ratio.OneToOne}
            />
          </ImageWrap>
          <RatingsPlaceholder>
            <Ratings average={props.averageRating} spacing={Spacing.Normal} size={Size.Tiny} />
          </RatingsPlaceholder>
        </RatingImageWrap>
        <CardInnerWrap>
          <Prices>
            <Price
              css={discount > 0 ? { fontSize: delta, color: wildwatermelon } : { fontSize: delta, color: black }}
              current={props.hasDiscount && !props.upsell ? Math.round(props.current) : Math.round(props.currentPrice)}
              currency={props.currency}
              priceType={props.hasDiscount ? PriceType.New : PriceType.Regular}
            />
            {props.productHasDiscount && (
              <Price
                css={{
                  fontSize: zeta,
                  marginLeft: pixelsToUnit(5),
                }}
                current={props.original}
                currency={props.currency}
                priceType={PriceType.Old}
              />
            )}
            {props.upsell > 0 && !props.productHasDiscount && props.currentPrice !== props.original && (
              <Price
                css={{
                  fontSize: zeta,
                  marginLeft: pixelsToUnit(5),
                }}
                current={props.original}
                currency={props.currency}
                priceType={PriceType.Old}
              />
            )}
          </Prices>
          {props.currentPrice !== props.original && (
            <SaveText>
              {translate('/Upsell/Save') + ' '}
              {Math.round(discount)}
              {translate('/Upsell/Currency')}
            </SaveText>
          )}
        </CardInnerWrap>
      </CardOuterWrap>
    </React.Fragment>
  );
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      items: state.cart.items,
    }),
    (dispatch): ConnectActionType => ({
      addToCart(code: string, quantity: number, ticket: string) {
        return dispatch(addCartItem(code, quantity, ticket, 'Kassakampanjer'));
      },
    }),
  )(Item),
);

export const Base = styled.div({
  backgroundColor: whitelilac,
  display: 'flex',
  flexFlow: 'column nowrap',
  flex: {
    grow: 0,
    shrink: 0,
    basis: pixelsToUnit(132),
  },
  padding: {
    xy: pixelsToUnit(2),
  },
  margin: { xy: '0.2rem' },
  textAlign: 'center',
  ...pseudo(':not(:first-child)', {
    marginLeft: pixelsToUnit(5),
  }),
});

const ContentLink = styled(Link, {
  color: (theme) => theme.textColor,
  display: 'flex',
  flexFlow: 'column nowrap',
  flexGrow: 2,
});

const RatingsPlaceholder = styled.div({
  height: pixelsToUnit(13),
  marginLeft: pixelsToUnit(5),
});

const Ratings = styled(AverageRating, {
  height: pixelsToUnit(13),
  width: '100%',
  zIndex: 1,
});

const Title = styled.div({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: zeta,
  fontWeight: 'bold',
  maxHeight: pixelsToUnit(66),
  width: pixelsToUnit(220),
  padding: {
    x: pixelsToUnit(10),
    bottom: 0,
  },
  lineHeight: pixelsToUnit(18),
  [minTinyMediaQuery]: {
    width: pixelsToUnit(320),
  },
  [minSmallMediaQuery]: {
    width: pixelsToUnit(360),
  },
});

const Prices = styled.div({
  flex: {
    grow: 0,
    shrink: 0,
    basis: pixelsToUnit(47),
  },
  fontSize: gamma,
  fontWeight: 'bold',
  maxHeight: pixelsToUnit(47),
});

const SaveText = styled.div({
  backgroundColor: wildwatermelon,
  color: white,
  padding: { xy: pixelsToUnit(2) },
  fontWeight: 'bold',
  height: pixelsToUnit(20),
  lineHeight: pixelsToUnit(15),
  fontSize: kappa,
  borderRadius: pixelsToUnit(3),
});

const CardOuterWrap = styled.div({
  display: 'flex',
  height: pixelsToUnit(60),
  justifyContent: 'space-between',
  alignItems: 'center',
});
const CardInnerWrap = styled.div({
  position: 'relative',
});

const CardWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ButtonWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingTop: pixelsToUnit(15),
  justifyContent: 'flex-end',
  flexGrow: 1,
});

const ImageWrap = styled.div({
  height: '50px',
  width: '50px',
  marginLeft: pixelsToUnit(10),
});

const CartIcon = styled(Icon, {
  fontSize: pixelsToUnit(20),
  paddingLeft: pixelsToUnit(1),
  paddingBottom: pixelsToUnit(1),
});

const RatingImageWrap = styled.div({
  display: 'flex',
});

const InfoIconStyled = styled(InfoIcon, {
  fontSize: pixelsToUnit(32),
});

const AddToCartStyled = styled(AddToCart, {
  height: pixelsToUnit(40),
  width: pixelsToUnit(40),
  borderRadius: '100%',
  backgroundColor: (theme) => theme.buyButtonProductcardMobile,
});
