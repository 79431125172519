import React from 'react';
import { styled } from '@glitz/react';
import { CartType } from 'Shared/State';
import { zeta, pixelsToUnit, huge } from 'Shared/Style';
import { ConnectPropType as CartActionsPropType } from 'Checkout/FullCart';
import Row from './Row';

export type TableHeadingsPropType = {
  tableHeadings: {
    productName: string;
    quantity: string;
    price: string;
    unitPrice: string;
    totalPrice: string;
  };
};

type PropType = {
  cart: CartType;
  isLoading: boolean;
} & TableHeadingsPropType &
  CartActionsPropType;

export default function(props: PropType) {
  const { tableHeadings, cart, isLoading } = props;

  return (
    <Table>
      <thead>
        <tr>
          <Media />
          <Name>{tableHeadings.productName}</Name>
          <Quantity>{tableHeadings.quantity}</Quantity>
          <UnitPrice>{tableHeadings.unitPrice}</UnitPrice>
          <TotalPrice>{tableHeadings.totalPrice}</TotalPrice>
          <Delete />
        </tr>
      </thead>
      <tbody>
        {cart.items.map(item => (
          <Row
            key= {`${item.code}|${item.isGift}`}
            item={item}
            currency={cart.currency}
            update={props.updateCartItemQuantity}
            delete={props.removeCartItem}
            isLoading={isLoading}
          />
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table({
  borderCollapse: 'collapse',
  borderSpacing: 0,
  tableLayout: 'fixed',
  width: '100%',
});

const headingStyled = styled({
  fontSize: zeta,
  fontWeight: 'normal',
  letterSpacing: pixelsToUnit(0.7),
  lineHeight: pixelsToUnit(18),
  textTransform: 'uppercase',
});

const Media = headingStyled(styled.Th, {
  width: '14%',
});

const Name = headingStyled(styled.Th, {
  paddingLeft: pixelsToUnit(24),
  textAlign: 'left',
  width: '33%',
});

const Quantity = headingStyled(styled.Th, {
  textAlign: 'center',
  width: '20%',
});

const UnitPrice = headingStyled(styled.Th, {
  textAlign: 'right',
  width: '13%',
});

const TotalPrice = headingStyled(styled.Th, {
  paddingLeft: huge,
  textAlign: 'right',
  width: '15%',
});

const Delete = styled.th({
  width: '5%',
});
