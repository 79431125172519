import React from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import AddressType from 'Shared/Address/AddressViewModel.type';
import CheckoutPageType from 'Checkout/Pages/Checkout/CheckoutPage.type';
import { Row } from '../Field';
import FieldWithValidation from '../Field/FieldWithValidation';

type PropType = {
  address: AddressType;
  page: CheckoutPageType;
  disabled?: boolean;
  onChange: (c: AddressType) => void;
  send: () => void;
  autoCompleteSection: string;
};

class Address extends React.Component<PropType> {
  update(fn: (c: AddressType) => void) {
    const newAddress = {} as AddressType;
    fn(newAddress);
    this.props.onChange(newAddress);
  }
  updateAndSend(fn: (c: AddressType) => void) {
    this.update(fn);
    this.props.send();
  }
  render() {
    const address = this.props.address || ({} as AddressType);
    return (
      <div>
        <Row>
          <FieldWithValidation
            type="text"
            required
            disabled={this.props.disabled}
            value={address.firstName}
            name="fname"
            autoComplete={this.props.autoCompleteSection + ' given-name'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.firstName = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.firstNameLabel)}

            placeholder={epiPropertyValue(this.props.page.firstNameLabel)}
            className="checkout-input"
          />
        </Row>
        <Row>
          <FieldWithValidation
            type="text"
            required
            disabled={this.props.disabled}
            value={address.lastName}
            name="lname"
            autoComplete={this.props.autoCompleteSection + ' family-name'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.lastName = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.lastNameLabel)}
            placeholder={epiPropertyValue(this.props.page.lastNameLabel)}
            className="checkout-input"
          />
        </Row>
        <Row>
          <FieldWithValidation
            type="text"
            required
            disabled={this.props.disabled}
            value={address.address1}
            name="address"
            autoComplete={this.props.autoCompleteSection + ' address-line1'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.address1 = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.address1Label)}
            placeholder={epiPropertyValue(this.props.page.address1Label)}
            className="checkout-input"
          />
        </Row>
        <Row>
          <FieldWithValidation
            type="text"
            required={false}
            value={address.address2}
            disabled={this.props.disabled}
            autoComplete={this.props.autoCompleteSection + ' address-line2'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.address2 = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.address2Label)}
            placeholder={epiPropertyValue(this.props.page.address2Label)}
            className="checkout-input"
          />
        </Row>
        <Row>
          <FieldWithValidation
            type="number"
            required
            disabled={this.props.disabled}
            value={address.postalCode}
            name="zip"
            autoComplete={this.props.autoCompleteSection + ' postal-code'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.postalCode = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.postalCodeLabel)}
            placeholder={epiPropertyValue(this.props.page.postalCodeLabel)}
            className="checkout-input"
          />
        </Row>
        <Row>
          <FieldWithValidation
            type="text"
            required
            disabled={this.props.disabled}
            value={address.city}
            name="city"
            autoComplete={this.props.autoCompleteSection + ' address-level2'}
            spellCheck={false}
            autoCorrect="off"
            onChange={e => this.update(a => (a.city = (e.target as HTMLInputElement).value))}
            onBlur={() => this.props.send()}
            label={epiPropertyValue(this.props.page.cityLabel)}
            placeholder={epiPropertyValue(this.props.page.cityLabel)}
            className="checkout-input"
          />
        </Row>
      </div>
    );
  }
}

export default Address;
