import React from 'react';
import { styled } from '@glitz/react';
import { epsilon, zeta, pixelsToUnit, thin, monochromeDark, sigma, tiny, small } from 'Shared/Style';
import {
  Media,
  DisplayName,
  UnitPrice,
  TotalPrice,
  Discount,
  PriceType,
  Message,
  QuantityField,
  Variant,
  Icon,
} from 'Checkout/FullCart/components';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';

type PropType = {
  item: CartItemViewModel;
  currency: string;
  isLoading: boolean;
  update: (code: string, quantity: number, prevQuantity: number) => Promise<void>;
  delete: (code: string, prevQuantity: number) => Promise<void>;
};

export default function (props: PropType) {
  function deleteItem() {
    return props.delete(props.item.code, props.item.quantity);
  }

  function updateItem(qty: number) {
    return props.update(props.item.code, qty, props.item.stockCount);
  }

  return (
    <Row>
      <Data>
        <Media
          productUrl={props.item.url}
          mediaSrc={props.item.imageUrl}
          alt={props.item.displayName}
          isGift={props.item.isGift}
        />
      </Data>
      <Data css={{ paddingLeft: pixelsToUnit(24) }}>
        <Name displayName={props.item.displayName} productUrl={props.item.url} isGift={props.item.isGift} />
        {props.item.message && <Message css={{ fontSize: zeta, marginTop: tiny }} message={props.item.message} />}
      </Data>
      <Data css={{ textAlign: 'center' }}>
        {!props.item.isGift ? (
          <QuantityField
            value={props.item.quantity}
            variant={Variant.Small}
            maxQuantity={props.item.stockCount}
            update={updateItem}
            tagDesigner={props.item.tagDesigner}
          />
        ) : (
          <QuantityField
            value={props.item.quantity}
            variant={Variant.Small}
            maxQuantity={props.item.stockCount}
            update={updateItem}
            tagDesigner={props.item.tagDesigner}
            hideIncrementDecrement
          />
        )}
      </Data>
      <Data css={{ textAlign: 'right' }}>
        <StyledUnitPrice
          css={{
            fontSize: props.item.hasDiscount ? pixelsToUnit(19) : epsilon,
            opacity: props.isLoading ? 0.5 : 1.0,
          }}
          priceType={props.item.hasDiscount ? PriceType.New : PriceType.Regular}
          price={props.item.price}
          currency={props.currency}
        />
      </Data>
      <Data css={{ textAlign: 'right', position: 'relative' }}>
        {props.item.hasDiscount && (
          <StyledDiscount
            css={{ opacity: props.isLoading ? 0.2 : 1.0, fontSize: zeta }}
            price={props.item.totalPrice - props.item.price * props.item.quantity}
            currency={props.currency}
          />
        )}

        <StyledTotalPrice
          css={{
            fontSize: props.item.hasDiscount ? pixelsToUnit(19) : epsilon,
            letterSpacing: pixelsToUnit(props.item.hasDiscount ? 0.25 : 0.21),
            opacity: props.isLoading ? 0.5 : 1.0,
          }}
          price={props.item.totalPrice}
          currency={props.currency}
          priceType={props.item.hasDiscount ? PriceType.New : PriceType.Regular}
        />
      </Data>
      <Data>
        {!props.item.isGift && (
          <Delete css={{ pointerEvents: props.isLoading ? 'none' : 'auto' }} onClick={deleteItem} />
        )}
      </Data>
    </Row>
  );
}

const Row = styled.tr({
  ':last-of-type': {
    borderBottom: {
      width: thin,
      style: 'solid',
      color: monochromeDark,
    },
  },
});

const Data = styled.td({
  height: pixelsToUnit(135),
  verticalAlign: 'middle',
});

const Name = styled(DisplayName, {
  fontSize: sigma,
  lineHeight: pixelsToUnit(19),
  letterSpacing: pixelsToUnit(0.18),
});

const StyledUnitPrice = styled(UnitPrice, {
  display: 'block',
  fontSize: epsilon,
  lineHeight: pixelsToUnit(20),
});

const StyledTotalPrice = styled(TotalPrice, {
  display: 'block',
  textAlign: 'right',
});

const StyledDiscount = styled(Discount, {
  position: 'absolute',
  right: 0,
  top: pixelsToUnit(32),
  display: 'block',
  textAlign: 'right',
});

const Delete = styled(Icon, {
  cursor: 'pointer',
  height: small,
  lineHeight: 'normal',
  marginLeft: pixelsToUnit(24),
  width: small,
  verticalAlign: 'middle',
  ':active': {
    transform: 'translateY(1px)',
  },
});
