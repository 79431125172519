import React from 'react';
import { pixelsToUnit } from 'Shared/Style';
import { styled } from '@glitz/react';

const Base = styled.div({
  left: pixelsToUnit(-5),
  position: 'absolute',
  top: pixelsToUnit(-5),
});

export default () => (
  <Base>
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="#13a970" strokeWidth="3" stroke="#13a970" />
    </svg>
  </Base>
);
