import React from 'react';
import { styled } from '@glitz/react';
import { getJson, translate, URLX, pathCombine, ENTER_KEY } from '@avensia/scope';
import { checkoutPageUrl } from 'Shared/known-urls';
import connect from 'Shared/connect';
import Text, { Appearance as InputAppearance } from 'Shared/Fields/Text';
import Select from 'Shared/Fields/Select';
import PostiiModel from './PostiiModel.type';
import { carnation, minMediumMediaQuery, large } from 'Shared/Style';
import DeliveryPoint from '../Shipping/DeliveryPointViewModel.type';
import {
  connectWithFeedback,
  ConnectPropType as FeedbackProps,
  Appearance as ButtonAppearance,
} from 'Shared/Button/Feedback';

const Wrapper = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  marginTop: large,
  [minMediumMediaQuery]: {
    flexDirection: 'row',
  },
});

const PostCode = styled.div({
  display: 'flex',
  margin: {
    right: large,
    bottom: large,
  },
});

const Input = styled(Text, {
  maxWidth: '200px',
  borderTopRightRadius: '0',
  borderBottomRightRadius: '0',
});

const Label = styled.span({
  alignSelf: 'center',
});

const ErrorLabel = styled.span({
  color: carnation,
  alignSelf: 'center',
});

type RequiredProps = {
  selectedDeliveryPoint: DeliveryPoint;
  onSelectDeliveryPoint: (deliveryPoint: DeliveryPoint) => any;
};
type ConnectStateType = {
  culture: string;
};
type Props = RequiredProps & FeedbackProps & ConnectStateType;

type State = {
  data: PostiiModel;
  postCode: string;
  loading: boolean;
  error: string;
};

const initialState: State = {
  data: null,
  loading: false,
  error: '',
  postCode: '',
};

class Postii extends React.Component<Props, State> {
  lastRequestId: number;
  search: HTMLButtonElement;
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  onEnter = (e: React.KeyboardEvent<{}>) => {
    if (e.which === ENTER_KEY && (e.target as HTMLInputElement).value !== '') {
      this.props.feedback.push(this.fetchResult());
    }
  };

  onPostcodeChange(code: string) {
    const postCode = code.replace(/\D/g, '');
    this.setState({ postCode });
  }

  fetchResult = async () => {
    const requestId = (this.lastRequestId = Date.now());
    this.setState({ loading: true, error: '' });

    try {
      const url = new URLX(pathCombine(checkoutPageUrl(), 'postii'));
      url.searchParams.set('postCode', this.state.postCode);
      const data: PostiiModel = await getJson(url);

      if (requestId !== this.lastRequestId) {
        return;
      }
      let error;
      if (data.locations.length === 0) {
        error = translate('/Checkout/Postii/NotFound');
      } else {
        error = '';
      }
      this.setState({ data, error, loading: false });
      this.props.onSelectDeliveryPoint(null);
    } catch (e) {
      this.setState({ data: null, loading: false, error: translate('/Checkout/Postii/Error') });
      this.props.onSelectDeliveryPoint(null);
      throw null;
    }
  };
  selectDeliveryPoint(value: string) {
    if (value.length === 0) {
      this.props.onSelectDeliveryPoint(null);
    } else {
      const location = this.state.data.locations.find(x => x.id === value);
      this.props.onSelectDeliveryPoint(location);
    }
  }
  render() {
    const selectedDeliveryPoint =
      this.props.selectedDeliveryPoint == null || this.props.selectedDeliveryPoint.id == null
        ? null
        : this.props.selectedDeliveryPoint;

    return (
      <Wrapper>
        <PostCode>
          <Input
            appearance={InputAppearance.Bare}
            placeholder={translate('/Checkout/Postii/Zipcode')}
            onKeyPress={this.onEnter}
            onChange={e => this.onPostcodeChange((e.target as HTMLInputElement).value)}
            value={this.state.postCode}
          />
          <this.props.feedback.Button
            style={{
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }}
            disabled={this.state.loading}
            appearance={ButtonAppearance.Secondary}
            onClick={() => this.props.feedback.push(this.fetchResult())}
          >
            {translate('/Checkout/Postii/Search')}
          </this.props.feedback.Button>
        </PostCode>
        {this.state.error.length === 0 ? (
          this.state.data ? (
            <Select
              options={this.state.data.locations.map(x => ({
                text: x.name ? x.name : '',
                value: x.id,
              }))}
              value={selectedDeliveryPoint ? this.props.selectedDeliveryPoint.id : ''}
              onChangeOption={value => this.selectDeliveryPoint(value)}
              placeholder={translate('/Checkout/Postii/Choose')}
            />
          ) : (
              selectedDeliveryPoint && <Label>{selectedDeliveryPoint.name}</Label>
            )
        ) : (
            <ErrorLabel>{this.state.error}</ErrorLabel>
          )}
      </Wrapper>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    culture: state.appShellData.culture,
  }),
)(connectWithFeedback()(Postii));
