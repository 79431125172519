import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import Address from '../Address';
import { Checkbox } from 'Shared/Fields/Toggle';
import OrdererType from './OrdererViewModel.type';
import CheckoutPageType from 'Checkout/Pages/Checkout/CheckoutPage.type';
import Button, { Appearance } from 'Shared/Button';
import { Row } from '../Field';
import FieldWithValidation from '../Field/FieldWithValidation';
import * as savedCustomerInfo from '../saved-customer-info';
import H1 from 'Shared/Generic/h1';
import H3 from 'Shared/Generic/H3';
import { whitelilac, pixelsToUnit } from 'Shared/Style';

type PropType = {
  orderer: OrdererType;
  onChange: (c: OrdererType) => void;
  send: () => void;
  page: CheckoutPageType;
};

type StateType = {
  editable?: boolean;
};

const Fieldset = styled.fieldset({
  maxWidth: '450px',
  margin: {
    bottom: '30px',
    x: 'auto',
  },
  ':last-of-type': {
    marginBottom: 0,
  },
});

const FormHeading = styled(H1, {
  textAlign: 'center',
  marginBottom: '30px',
});

const FormSubHeading = styled(H3, {
  textAlign: 'center',
  marginBottom: '30px',
});

const CheckboxWrapper = styled.div({
  textAlign: 'center',
});

const CheckboxLabel = styled.span({
  marginLeft: '12px',
});

const CustomerDetailsWrapper = styled.div({
  backgroundColor: whitelilac,
  padding: {
    xy: pixelsToUnit(30),
  },
  margin: {
    bottom: pixelsToUnit(30),
    x: pixelsToUnit(-30),
  },
});

const CustomerList = styled.ul({
  maxWidth: '360px',
  margin: {
    y: 0,
    x: 'auto',
  },
});

const CustomerItem = styled.li({
  display: 'flex',
  justifyContent: 'space-between',
  listStyle: 'none',
  marginBottom: '30px',
  ':last-child': {
    marginBottom: 0,
  },
});

const Right = styled.div({
  textAlign: 'right',
});

const CustomerLabel = styled(applyClassName(EpiProperty), {
  display: 'block',
});

const CustomerText = styled.span({
  display: 'block',
});

const SubmitButton = styled(Button, {
  display: 'block !important',
  margin: {
    y: 0,
    x: 'auto',
  },
});

export default class Orderer extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      editable: !props.orderer.hasEnoughInfoToCompletePurchase,
    };
  }
  UNSAFE_componentWillMount() {
    if (isEmpty(this.props.orderer)) {
      const savedInfo = savedCustomerInfo.get();
      if (savedInfo && !isEmpty(savedInfo)) {
        this.setState({ editable: false });
        this.props.onChange(savedInfo);
        this.props.send();
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    if (!nextProps.orderer.hasEnoughInfoToCompletePurchase && !this.state.editable) {
      this.setState({ editable: true });
    }
  }
  update(fn: (c: OrdererType) => void) {
    const newOrderer = {} as OrdererType;
    fn(newOrderer);
    this.props.onChange(newOrderer);
  }
  updateAndSend(fn: (c: OrdererType) => void) {
    this.update(fn);
    this.props.send();
  }
  render() {
    return (
      <div>
        <EpiProperty
          component={FormHeading}
          for={this.state.editable ? this.props.page.customerInfoHeading : this.props.page.prefilledCustomerInfoHeading}
        />
        {this.state.editable ? (
          <div>
            <Fieldset>
              <Row>
                <FieldWithValidation
                  type="email"
                  required
                  value={this.props.orderer.email}
                  name="email"
                  autoComplete="email"
                  onChange={e => this.update(o => (o.email = (e.target as HTMLInputElement).value))}
                  onBlur={() => this.props.send()}
                  label={epiPropertyValue(this.props.page.emailLabel)}
                  placeholder={epiPropertyValue(this.props.page.emailLabel)}
                  className="checkout-input"
                />
              </Row>
              <Row>
                <FieldWithValidation
                  type="tel"
                  required
                  value={this.props.orderer.phoneNumber}
                  name="mobile"
                  autoComplete="tel"
                  spellCheck={false}
                  autoCorrect="off"
                  onChange={e => this.update(o => (o.phoneNumber = (e.target as HTMLInputElement).value))}
                  onBlur={() => this.props.send()}
                  label={epiPropertyValue(this.props.page.phoneNumberLabel)}
                  placeholder={epiPropertyValue(this.props.page.phoneNumberLabel)}
                  className="checkout-input"
                />
              </Row>
              <Address
                address={this.props.orderer.billingAddress}
                page={this.props.page}
                // tslint:disable-next-line:jsx-no-lambda
                onChange={newAddress => this.update(o => (o.billingAddress = newAddress))}
                send={this.props.send}
                autoCompleteSection="billing"
              />
              <CheckboxWrapper>
                <Checkbox
                  checked={this.props.orderer.hasAlternativeDeliveryAddress}
                  // tslint:disable-next-line:jsx-no-lambda
                  onChange={(e: React.FormEvent<HTMLElement>) =>
                    this.updateAndSend(o => (o.hasAlternativeDeliveryAddress = (e.target as HTMLInputElement).checked))
                  }
                >
                  <CheckboxLabel>
                    <EpiProperty for={this.props.page.alternativeDeliveryAddressLabel} component="span" />
                  </CheckboxLabel>
                </Checkbox>
              </CheckboxWrapper>
            </Fieldset>
            {this.props.orderer.hasAlternativeDeliveryAddress && (
              <Fieldset>
                <EpiProperty for={this.props.page.alternativeDeliveryAddressHeading} component={FormSubHeading} />
                <Address
                  address={this.props.orderer.alternativeDeliveryAddress}
                  page={this.props.page}
                  // tslint:disable-next-line:jsx-no-lambda
                  onChange={newAddress => this.update(o => (o.alternativeDeliveryAddress = newAddress))}
                  send={this.props.send}
                  autoCompleteSection="shipping"
                />
              </Fieldset>
            )}
          </div>
        ) : (
            <div>
              <CustomerDetailsWrapper>
                <CustomerList>
                  <CustomerItem>
                    <div>
                      <CustomerLabel for={this.props.page.firstNameLabel} component="b" />
                      <CustomerText>
                        {this.props.orderer.billingAddress.firstName} {this.props.orderer.billingAddress.lastName}
                      </CustomerText>
                    </div>
                    <Right>
                      <CustomerLabel for={this.props.page.emailLabel} component="b" />
                      <CustomerText>{this.props.orderer.email}</CustomerText>
                    </Right>
                  </CustomerItem>
                  <CustomerItem>
                    <div>
                      <CustomerLabel for={this.props.page.address1Label} component="b" />
                      <CustomerText>{this.props.orderer.billingAddress.address1}</CustomerText>
                      <CustomerText>{this.props.orderer.billingAddress.address2}</CustomerText>
                      <CustomerText>{this.props.orderer.billingAddress.postalCode}</CustomerText>
                      <CustomerText>{this.props.orderer.billingAddress.city}</CustomerText>
                    </div>
                    <Right>
                      <CustomerLabel for={this.props.page.phoneNumberLabel} component="b" />
                      <CustomerText>{this.props.orderer.phoneNumber}</CustomerText>
                    </Right>
                  </CustomerItem>
                </CustomerList>
              </CustomerDetailsWrapper>
              <SubmitButton onClick={() => this.setState({ editable: true })} appearance={Appearance.Secondary}>
                <EpiProperty for={this.props.page.editCustomerDetailsText} component="b" />
              </SubmitButton>
            </div>
          )}
      </div>
    );
  }
}

function isEmpty(object: Object): boolean {
  return Object.keys(object).every(
    key => !object[key] || (typeof object[key] === 'object' ? isEmpty(object[key]) : false),
  );
}
