import React from 'react';
import connect from 'Shared/connect';
import { CartType } from 'Shared/State';
import Viewport from 'Shared/Viewport';
import DesktopCart, { TableHeadingsPropType } from './DesktopCart';
import MobileCart from './MobileCart';
import { updateCartItemQuantity, removeCartItem } from '../action-creators';

export type RequiredPropType = {
  cart: CartType;
  isLoading: boolean;
} & TableHeadingsPropType;

export type ConnectPropType = {
  updateCartItemQuantity: (code: string, quantity: number) => Promise<void>;
  removeCartItem: (code: string) => Promise<void>;
};

type PropType = RequiredPropType & ConnectPropType;

function FullCart(props: PropType) {
  return (
    <Viewport>
      {(isCompact: boolean) =>
        isCompact ? (
          <MobileCart
            cart={props.cart}
            isLoading={props.isLoading}
            updateCartItemQuantity={props.updateCartItemQuantity}
            removeCartItem={props.removeCartItem}
          />
        ) : (
          <DesktopCart
            tableHeadings={props.tableHeadings}
            cart={props.cart}
            isLoading={props.isLoading}
            updateCartItemQuantity={props.updateCartItemQuantity}
            removeCartItem={props.removeCartItem}
          />
        )
      }
    </Viewport>
  );
}

export default connect(
  null,
  (dispatch): ConnectPropType => ({
    updateCartItemQuantity(code: string, quantity: number, maxQuantity: number = 0) {
      return dispatch(updateCartItemQuantity(code, quantity, 'Kassasida', null, maxQuantity));
    },
    removeCartItem(code: string) {
      return dispatch(removeCartItem(code, 'Kassasida'));
    },
  }),
)(FullCart);
