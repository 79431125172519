import React from 'react';
import { styled } from '@glitz/react';
import { Radio } from 'Shared/Fields/Toggle';
import ShippingMethodType from './ShippingMethodViewModel.type';
import Price from 'Pricing/Price';
import { theta, minTinyMediaQuery, delta, zeta, pixelsToUnit } from 'Shared/Style';
import Postii from '../Postii';
import DeliveryPoint from './DeliveryPointViewModel.type';

type PropType = {
  method: ShippingMethodType;
  selectedMethodId: string;
  currency: string;
  onSelect: (id: string) => void;
  selectedDeliveryPoint: DeliveryPoint;
  onSelectDeliveryPoint: (deliveryPoint: DeliveryPoint) => any;
};

const MethodName = styled.span({
  fontSize: theta,
  [minTinyMediaQuery]: {
    fontSize: delta,
  },
});

const ShippingWrapper = styled.li({
  alignItems: 'flex-start',
  display: 'flex',
  paddingBottom: pixelsToUnit(20),
  ':last-child': {
    paddingBottom: 0,
  },
});

const PriceText = styled.span({
  fontWeight: 'bold',
});

const MethodDescription = styled.p({
  font: {
    size: zeta,
    style: 'italic',
  },
});

const Method = styled.div({
  paddingLeft: pixelsToUnit(20),
});

export default (props: PropType) => {
  const { id, name, price, type, description } = props.method;
  function setShippingMethod(event: React.ChangeEvent<HTMLInputElement>) {
    props.onSelect((event.target as HTMLInputElement).value);
  }
  return (
    <ShippingWrapper>
      <Radio name="shippingmethod" value={id} checked={id === props.selectedMethodId} onChange={setShippingMethod} />
      <Method>
        <MethodName>
          {name}
          {price > 0 && ', '}
          <PriceText>{price > 0 && <Price current={price} currency={props.currency} />}</PriceText>
        </MethodName>
        <MethodDescription>{description}</MethodDescription>
        {id === props.selectedMethodId &&
          type === 'Posti' && (
            <Postii
              selectedDeliveryPoint={props.selectedDeliveryPoint}
              onSelectDeliveryPoint={props.onSelectDeliveryPoint}
            />
          )}
      </Method>
    </ShippingWrapper>
  );
};
