import * as React from 'react';
import { resolveComponentAndChildComponents, resolveSyncIfLoadedOrThrow } from '@avensia/scope/resolve-component';

export type ChangeType = {
  email: string;
  mobileNumber: string;
  personalNumber: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  address: {
    city: string,
    firstName: string,
    isMasked: Boolean,
    lastName: string,
    postalCode: string,
    street: string
  }
};

type PropType = {
  snippet: string;
  isLoading: boolean;
  onInfoChanged: (change: ChangeType) => void;
  onLockInput?: (visibleElement: HTMLElement, keepGatewayEnabled: boolean) => void;
  onUnlockInput?: () => void;
  onPaymentFailed?: (messages: string[]) => void;
  email?: string;
  reloadCart?: () => Promise<void>;
};

type StateType = {
  Component?: React.ComponentClass<any> | React.StatelessComponent<any>;
  model?: { componentName: string };
};

function setLoading(isLoading: boolean) {
  console.info((isLoading ? 'Suspending' : 'Resuming') + ' interactive payment');
  dispatch('interactive-payment-set-loading', isLoading);
}

function dispatch(type: string, detail: any) {
  var e;
  try {
    e = new CustomEvent(type, { detail });
  } catch (ex) {
    e = document.createEvent('CustomEvent');
    e.initCustomEvent(type, true, false, detail);
  }
  document.dispatchEvent(e);
}

export default class InteractivePaymentGatewayAdapter extends React.Component<PropType, StateType> {
  cover: HTMLElement;
  element: HTMLElement;
  prevElement: HTMLElement;
  isCompletingPurchase: boolean;
  rejectCurrentPurchase: () => void;
  constructor(props: PropType) {
    super(props);

    this.state = { model: props.snippet && props.snippet[0] === '{' ? JSON.parse(props.snippet) : null };
    if (this.state.model) {
      this.loadComponent(this.state.model);
    }
    this.infoChangedHandler = this.infoChangedHandler.bind(this);
    this.lockHandler = this.lockHandler.bind(this);
    this.unlockHandler = this.unlockHandler.bind(this);
    this.failedHandler = this.failedHandler.bind(this);
    this.reloadHandler = this.reloadHandler.bind(this);
  }
  componentWillReceiveProps(nextProps: PropType) {
    if (nextProps.isLoading !== this.props.isLoading) {
      setLoading(nextProps.isLoading);
    }
    if (nextProps.snippet && nextProps.snippet[0] === '{') {
      const model = JSON.parse(nextProps.snippet);
      this.setState({ model });
      if (this.state.Component && (!this.state.model || this.state.model.componentName != model.componentName)) {
        this.setState({ Component: null });
      }
      this.loadComponent(model);
    } else {
      this.setState({ model: null, Component: null });
    }
  }
  componentWillMount() {
    if (window.document) {
      document.addEventListener('interactive-payment-info-changed', this.infoChangedHandler);
      document.addEventListener('interactive-payment-lock', this.lockHandler);
      document.addEventListener('interactive-payment-unlock', this.unlockHandler);
      document.addEventListener('interactive-payment-failed', this.failedHandler);
      document.addEventListener('interactive-payment-reload', this.reloadHandler);
    }
  }
  componentWillUnmount() {
    document.removeEventListener('interactive-payment-info-changed', this.infoChangedHandler);
    document.removeEventListener('interactive-payment-lock', this.lockHandler);
    document.removeEventListener('interactive-payment-unlock', this.unlockHandler);
    document.removeEventListener('interactive-payment-failed', this.failedHandler);
    document.removeEventListener('interactive-payment-reload', this.reloadHandler);
    if (this.cover) {
      this.cover.parentElement.removeChild(this.cover);
      this.cover = null;
    }
  }
  shouldComponentUpdate(nextProps: PropType, nextState: StateType) {
    if (this.state.model) {
      return true;
    } else {
      return this.props.snippet !== nextProps.snippet;
    }
  }
  infoChangedHandler(e: CustomEvent) {
    this.props.onInfoChanged(e.detail);
    setLoading(false);
  }
  reloadHandler() {
    if (this.props.reloadCart) {
      this.props.reloadCart();
    } else {
      throw 'No reload handler has been specified';
    }
  }
  lockHandler(e: CustomEvent) {
    const visibleElement = e.detail && e.detail.visibleElement;
    const keepGatewayEnabled = !!(e.detail && e.detail.keepGatewayEnabled);
    if (this.props.onLockInput) {
      this.props.onLockInput(visibleElement, keepGatewayEnabled);
    } else {
      if (keepGatewayEnabled) {
        console.warn(
          'Gateway requested to lock the page with the gateway still visible, but no onLockInput callback was specified for the adapter',
        );
      }
      const cover = document.createElement('div');
      cover.style.position = 'fixed';
      cover.style.top = cover.style.left = cover.style.bottom = cover.style.right = '0';
      cover.style.zIndex = '10000';
      cover.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';

      if (visibleElement) {
        cover.style.display = 'flex';
        cover.style.justifyContent = 'center';
        cover.style.alignItems = 'center';

        var inner = document.createElement('div');
        inner.style.backgroundColor = 'white';
        inner.style.border = 'solid 1px black';
        inner.appendChild(visibleElement);

        cover.appendChild(inner);
      }

      document.body.appendChild(cover);
      this.cover = cover;
    }
  }
  failedHandler(e: CustomEvent) {
    if (this.rejectCurrentPurchase) {
      this.rejectCurrentPurchase();
      this.isCompletingPurchase = false;
      this.rejectCurrentPurchase = null;
    }
    if (this.props.onPaymentFailed) {
      this.props.onPaymentFailed((e.detail && e.detail.errors) || []);
    }
  }
  unlockHandler(e: CustomEvent) {
    if (this.props.onLockInput) {
      this.props.onUnlockInput();
    } else if (this.cover) {
      this.cover.parentElement.removeChild(this.cover);
      this.cover = null;
    }
  }
  loadComponent(model: { componentName: string }) {
    resolveComponentAndChildComponents(model).then(() => {
      if (this.state.model && this.state.model.componentName === model.componentName) {
        this.setState({ Component: resolveSyncIfLoadedOrThrow(model.componentName) });
      }
    });
  }
  completePurchase(amount: number) {
    if (this.isCompletingPurchase) {
      return Promise.reject('Cannot perform two purchases at the same time');
    }
    return new Promise<void>((resolve, reject) => {
      this.rejectCurrentPurchase = reject;
      dispatch('interactive-payment-complete-purchase', { amount });
    });
  }
  componentDidMount() {
    this.componentDidUpdate();
  }
  async componentDidUpdate() {
    if (this.element && (this.element !== this.prevElement || this.props.snippet !== this.element.dataset['snippet'])) {
      this.element.innerHTML = this.props.snippet;
      this.element.dataset['snippet'] = this.props.snippet;

      const loadScript = (el: HTMLScriptElement) => {
        const parent = el.parentElement;
        const next = el.nextSibling;
        parent.removeChild(el);
        return new Promise<void>((resolve) => {
          const newEl = document.createElement('script');
          for (let i = 0; i < el.attributes.length; i++) {
            const a = el.attributes[i];
            newEl.setAttribute(a.name, a.value);
          }

          const onLoad = () => {
            newEl.removeEventListener('load', onLoad);
            resolve();
          };
          newEl.addEventListener('load', onLoad);

          next ? parent.insertBefore(newEl, next) : parent.appendChild(newEl);
        });
      };

      // Scripts do not get executed by just adding them to an inner element that is dynamically added to the DOM
      const scripts = Array.prototype.slice.call(this.element.querySelectorAll('script')) as HTMLScriptElement[];
      for (let script of scripts) {
        if (script.src) {
          if (script.async || script.defer) {
            loadScript(script); // async/deferred so we can safely execute the next scripts
          } else {
            await loadScript(script);
          }
        } else {
          new Function(script.text)();
        }
      }

      if (this.props.isLoading) {
        setLoading(true);
      }
    }
    this.prevElement = this.element;
  }
  render() {
    if (!this.props.snippet) {
      return <div />;
    } else if (this.state.model) {
      const Component = this.state.Component;
      return Component ? <Component {...this.state.model} email={this.props.email} /> : <div />;
    } else {
      return <div ref={(e) => (this.element = e)} />;
    }
  }
}
