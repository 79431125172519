import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { pixelsToUnit, kappa, tundora, mountainmeadow, sigma, minMediumMediaQuery, delison, theta } from 'Shared/Style';
import Icon from 'Shared/Icon/Check';
import OrderPromotionType from 'Checkout/Order/OrderDetails/OrderPromotion.type';

type PropType = {
  promotions: OrderPromotionType[];
  activatedText: string;
};

function Promotions(props: PropType & StyledProps) {
  return (
    <styled.Div css={props.compose()}>
      <Title>{props.activatedText}</Title>
      <Ul>
        {props.promotions.map((promotion, i) => (
          <Li key={promotion.name + i}>
            <Check />
            <PromotionName>{promotion.name}</PromotionName>
          </Li>
        ))}
      </Ul>
    </styled.Div>
  );
}

export default styled(Promotions);

const Title = styled.h2({
  color: tundora,
  fontSize: sigma,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.15),
  lineHeight: pixelsToUnit(19),
  marginBottom: pixelsToUnit(10),
  [minMediumMediaQuery]: {
    fontSize: delison,
  },
});

const Ul = styled.ul({
  listStyle: 'none',
});

const Li = styled.li({
  lineHeight: pixelsToUnit(18),
  ...pseudo(':not(:last-child)', {
    marginBottom: pixelsToUnit(11),
  }),
});

const Check = styled(Icon, {
  color: mountainmeadow,
  width: pixelsToUnit(18),
  height: pixelsToUnit(18),
  verticalAlign: 'text-bottom',
});

const PromotionName = styled.span({
  color: tundora,
  fontSize: kappa,
  letterSpacing: pixelsToUnit(0.14),
  marginLeft: pixelsToUnit(6),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: theta,
  },
});
