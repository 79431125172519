import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { EpiProperty } from '@avensia/scope-episerver';
import Price, { Type as PriceType } from 'Pricing/Price';
import { mountainmeadow, sigma, pixelsToUnit, minMediumMediaQuery, theta, delison, gamma, iota } from 'Shared/Style';

type PropType = {
  totalPrice: number;
  totalPriceText: Scope.Property<string>;
  totalSavings: number;
  totalDiscountText: Scope.Property<string>;
  paymentFeeText: Scope.Property<string>;
  paymentFee: number;
  shippingFee: number;
  shippingFeeText: Scope.Property<string>;
  currency: string;
  children?: React.ReactNode;
  isLoading: boolean;
};

function Summary(props: PropType & StyledProps) {
  return (
    <styled.Div css={props.compose()}>
      <Table>
        <tbody>
          {props.totalSavings > 0 && (
            <tr>
              <EpiProperty component={Heading} for={props.totalDiscountText} />
              <Value>
                <StyledPrice current={props.totalSavings} currency={props.currency} priceType={props.totalSavings > 0 ? PriceType.Discount : PriceType.Regular} />
              </Value>
            </tr>
          )}
          <tr>
            <EpiProperty component={Heading} for={props.shippingFeeText} />
            <Value>
              <StyledPrice current={props.shippingFee} currency={props.currency} />
            </Value>
          </tr>
          <tr>
            <EpiProperty component={Heading} for={props.paymentFeeText} />
            <Value>
              <StyledPrice current={props.paymentFee} currency={props.currency} />
            </Value>
          </tr>
          <tr>
            <EpiProperty component={TotalPriceHeading} for={props.totalPriceText} />
            <Value css={{ opacity: props.isLoading ? 0.5 : 1.0 }}>
              <TotalPrice current={props.totalPrice} currency={props.currency} />
            </Value>
          </tr>
        </tbody>
      </Table>
    </styled.Div>
  );
}

export default styled(Summary);

const Heading = styled.th({
  fontSize: sigma,
  fontWeight: 'normal',
  letterSpacing: pixelsToUnit(0.1),
  lineHeight: pixelsToUnit(26),
  textAlign: 'left',
  [minMediumMediaQuery]: {
    fontSize: theta,
    letterSpacing: pixelsToUnit(0.2),
    lineHeight: pixelsToUnit(30),
    textAlign: 'right',
  },
});

const TotalPriceHeading = styled(Heading, {
  [minMediumMediaQuery]: {
    fontWeight: 'bold',
  },
});

const Value = styled.td({
  textAlign: 'right',
  verticalAlign: 'middle',
});

const StyledPrice = styled(Price, {
  fontSize: delison,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.2),
  lineHeight: pixelsToUnit(26),
  [minMediumMediaQuery]: {
    fontSize: gamma,
    lineHeight: pixelsToUnit(30),
  },
});

const TotalPrice = styled(StyledPrice, {
  [minMediumMediaQuery]: {
    fontSize: iota,
    color: mountainmeadow,
  },
});

const Table = styled.table({
  borderCollapse: 'collapse',
  borderSpacing: 0,
  tableLayout: 'fixed',
  width: '100%',
});
