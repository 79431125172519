import React, { useRef } from 'react';
import { styled } from '@glitz/react';
import { Translate, LinksContainer } from '@avensia/scope';
import * as style from 'Shared/Style';
import executeScripts from '@avensia-oss/execute-scripts';

// https://developer.ingrid.com/siw.html

const CONTAINER_ID = 'shipwallet-container';

export const ingridApi = (func: (api: any) => void) =>
  window._sw &&
  window._sw((api: any) => {
    func(api);
  });

const IngridCouldNotLoad = styled.div({
  padding: { x: style.huge, y: style.gigantic },
  textAlign: 'center',
  fontSize: style.epsilon,
});

type WigetProps = {
  widgetHtml: string;
  sessionId: string;
  onDataChanged: any;
  onAddressChanged: any;
};

async function init(snippetHtml: string | null, elementRef: React.RefObject<HTMLDivElement>) {
  if (snippetHtml && elementRef.current) {
    elementRef.current.innerHTML = snippetHtml;
    const node = document.getElementById(CONTAINER_ID);
    if (node) {
      await executeScripts(node);
    }
  }
}

export default function IngridWidget(props: WigetProps) {
  const widgetWrapperRef = useRef<HTMLDivElement>(null);
  const sessionIdPrev = useRef<string>();

  function onIngridUpdate() {
    if (props.sessionId !== '0' && sessionIdPrev.current !== props.sessionId) {
      init(props.widgetHtml, widgetWrapperRef);
      ingridApi(api => {
          api.on("data_changed", function (data: { external_method_id: string, price: number }, meta: { external_method_id_changed: boolean, price_changed: boolean }) {
            if (meta.external_method_id_changed || meta.price_changed) {
              props.onDataChanged(data.external_method_id, data.price);
            }
          });

          api.on('address_changed', (address: any) => {
            if (!address.postal_code) {
              return;
            }
            props.onAddressChanged(address.postal_code);
          });
      });
    }
    sessionIdPrev.current = props.sessionId;
  }

  React.useEffect(onIngridUpdate, [props.sessionId]);

  return props.sessionId === '0' ? (
    <IngridCouldNotLoad>
      <Translate phrase="/Checkout/Ingrid/CouldNotLoad" />
    </IngridCouldNotLoad>
  ) : (
    <LinksContainer>
      <div ref={widgetWrapperRef} />
    </LinksContainer>
  );
}