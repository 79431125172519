import React from 'react';
import { styled } from '@glitz/react';
import { pixelsToUnit, minTinyMediaQuery } from 'Shared/Style';

function getStyledContent(innerColor: string, sideColor: string) {
  return styled(BaseContent, {
    ':before': {
      borderLeft: {
        width: '50vw',
        style: 'solid',
        color: sideColor,
      },
      borderTop: {
        width: pixelsToUnit(25),
        style: 'solid',
        color: innerColor,
      },
      position: 'absolute',
      content: "''",
      left: 0,
    },
    ':after': {
      borderRight: {
        width: '50vw',
        style: 'solid',
        color: sideColor,
      },
      borderTop: {
        width: pixelsToUnit(25),
        style: 'solid',
        color: innerColor,
      },
      position: 'absolute',
      content: "''",
      right: 0,
    },
    [minTinyMediaQuery]: {
      ':before': {
        borderLeft: {
          width: pixelsToUnit(80),
        },
      },
      ':after': {
        borderRight: {
          width: pixelsToUnit(80),
        },
      },
    },
  });
}

type PropType = {
  innerColor: string;
  sideColor: string;
};

export default function Divider(props: PropType) {
  const backgroundColor = props.sideColor;
  const Content = getStyledContent(props.innerColor, props.sideColor);
  return (
    <Base css={{ backgroundColor }}>
      <Content />
    </Base>
  );
}

const Base = styled.div({
  width: '100%',
  position: 'relative',
  height: pixelsToUnit(25),
});

const BaseContent = styled.div({
  width: '100vw',
  height: pixelsToUnit(25),
  margin: {
    x: 'auto',
  },
  left: 0,
  right: 0,
  position: 'relative',
  [minTinyMediaQuery]: {
    width: pixelsToUnit(160),
  },
});
