import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate } from '@avensia/scope';
import { EpiProperty } from '@avensia/scope-episerver';
import Price, { Variant as PriceVariant } from 'Pricing/Price';
import CartValidationResultType from 'Cart/CartValidationResult.type';
import Ul from 'Shared/Generic/Ul';
import { ConnectFeedbackButtonType, Variant as ButtonVariant } from 'Shared/Button/Feedback';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import CheckoutPageType from 'Checkout/Pages/Checkout/CheckoutPage.type';
import { mountainmeadow } from 'Shared/Style';

type PropType = {
  currency: string;
  FeedbackButton: ConnectFeedbackButtonType;
  checkoutPage: CheckoutPageType;
  isLoading: boolean;
  totalPrice: number;
  paymentFee: number;
  paymentFeeText: Scope.Property<string>;
  shippingFee: number;
  shippingFeeText: Scope.Property<string>;
  heading: Scope.Property<string>;
  completePurchaseText: Scope.Property<string>;
  serverValidationErrors: CartValidationResultType;
  somethingWasUpdatedDuringCompletePurchase: boolean;
  paymentErrorMessage: string;
} & StyledProps;

const Wrapper = styled.div({
  maxWidth: '480px',
  margin: {
    y: 0,
    x: 'auto',
  },
});

const Heading = styled.h2({
  fontSize: '32px',
  fontWeight: 'normal',
  marginBottom: '30px',
  textAlign: 'center',
});

const PriceList = styled.ul({
  maxWidth: '300px',
  margin: {
    top: 0,
    bottom: '60px',
    x: 'auto',
  },
});

const PriceItem = styled.li({
  display: 'flex',
  justifyContent: 'space-between',
});

const TermsBtn = styled.a({
  borderBottom: {
    width: '1px',
    style: 'solid',
    color: '#513265',
  },
  cursor: 'pointer',
});

function CompletePurchase(props: PropType) {
  const FeedbackButton = props.FeedbackButton;
  return (
    <Wrapper css={props.compose()}>
      <EpiProperty component={Heading} for={props.heading} />
      <PriceList>
        <PriceItem>
          <span>{translate('/Payment/ShippingFeeLineCode')}</span>
          <Price
            css={{ fontWeight: 'bold' }}
            current={props.shippingFee}
            currency={props.currency}
            variant={PriceVariant.Medium}
          />
        </PriceItem>
        <PriceItem>
          <span>{translate('/Payment/PaymentFee')}</span>
          <Price
            css={{ fontWeight: 'bold' }}
            current={props.paymentFee}
            currency={props.currency}
            variant={PriceVariant.Medium}
          />
        </PriceItem>
        <PriceItem>
          <b>{translate('/Payment/totalPrice')}</b>
          <Price
            css={{
              fontWeight: 'bold',
              color: mountainmeadow,
            }}
            current={props.totalPrice}
            currency={props.currency}
            variant={PriceVariant.Medium}
          />
        </PriceItem>
      </PriceList>
      <FeedbackButton
        css={{
          width: '100%',
          marginBottom: '30px',
        }}
        type="submit"
        variant={ButtonVariant.Large}
      >
        <EpiProperty component="span" for={props.completePurchaseText} />
      </FeedbackButton>
      <span>
        {translate('/Payment/TermsAndConditions')} <TermsBtn>{translate('/Payment/Conditions')}</TermsBtn>
      </span>
      {props.serverValidationErrors &&
        !props.serverValidationErrors.success && (
          <ErrorMessage>
            <Ul
              css={{
                listStyle: 'none',
                textAlign: 'center',
              }}
            >
              {validationMessage(
                props.serverValidationErrors.hasMissingShippingMethod,
                props.checkoutPage.missingShippingMethodText,
              )}
              {validationMessage(
                props.serverValidationErrors.hasMissingPaymentMethod,
                props.checkoutPage.missingPaymentMethodText,
              )}
              {validationMessage(props.serverValidationErrors.hasMissingEmail, props.checkoutPage.missingEmailText)}
              {validationMessage(props.serverValidationErrors.hasInvalidEmail, props.checkoutPage.invalidEmailText)}
              {validationMessage(
                props.serverValidationErrors.hasMissingFirstName,
                props.checkoutPage.missingFirstNameText,
              )}
              {validationMessage(
                props.serverValidationErrors.hasMissingLastName,
                props.checkoutPage.missingLastNameText,
              )}
              {validationMessage(props.serverValidationErrors.hasMissingAddress, props.checkoutPage.missingAddressText)}
              {validationMessage(
                props.serverValidationErrors.hasMissingPostalCode,
                props.checkoutPage.missingPostalCodeText,
              )}
              {validationMessage(props.serverValidationErrors.hasMissingCity, props.checkoutPage.missingCityText)}
              {validationMessage(
                props.serverValidationErrors.hasMissingPhoneNumber,
                props.checkoutPage.missingPhoneNumberText,
              )}
              {validationMessage(props.serverValidationErrors.hasItemWithError, props.checkoutPage.unavailableItemText)}
            </Ul>
          </ErrorMessage>
        )}
      {props.somethingWasUpdatedDuringCompletePurchase && (
        <EpiProperty component={ErrorMessage} for={props.checkoutPage.genericErrorMessageText} />
      )}
      {props.paymentErrorMessage && <ErrorMessage>{props.paymentErrorMessage}</ErrorMessage>}
    </Wrapper>
  );
}

function validationMessage(shouldShow: boolean, text: Scope.Property<string> | string) {
  return shouldShow && (typeof text === 'string' ? <li>{text}</li> : <EpiProperty component="li" for={text} />);
}

export default styled(CompletePurchase, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
