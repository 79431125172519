import { styled } from '@glitz/react';
import Input from 'Shared/Fields/Text';

export const Row = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  marginBottom: '25px',
});

export const Field = styled(Input, {
  textAlign: 'center',
  flexGrow: 1,
  minWidth: '20ch',
});
