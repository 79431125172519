import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { General as Variant, eta, minTinyMediaQuery, redRibbon, minMediumMediaQuery } from 'Shared/Style';
import SharedInput from 'Shared/Fields/Text';
import FeedbackButton, { Appearance } from 'Shared/Button/Feedback';
import { tundora, kappa, pixelsToUnit, thick } from 'Shared/Style';
import CheckoutUpdateResultType from 'Checkout/Pages/Checkout/CheckoutUpdateResult.type';

type StateType = {
  code: string;
  showInput: boolean;
  hasError: boolean;
};

type RequiredPropType = {
  title: string;
  addCode: (code: string) => Promise<CheckoutUpdateResultType>;
  errorMessage?: string;
};

type PropType = RequiredPropType;

export default class CodeInput extends React.Component<PropType, StateType> {
  ref = React.createRef<HTMLInputElement>();

  constructor(props: PropType) {
    super(props);
    this.state = {
      code: '',
      showInput: false,
      hasError: false,
    };
  }
  componentDidUpdate = (prevProps: PropType, prevState: StateType) => {
    if (!prevState.showInput && this.state.showInput) {
      this.ref.current.focus();
    }
  };
  showInput = () => {
    this.setState({ showInput: true });
  };
  reset = () => this.setState(() => ({ code: '', hasError: false }));
  inputCode = (e: React.FormEvent<HTMLElement>) => this.setState({ code: (e.target as HTMLInputElement).value.trim() });

  showMessage() {
    this.setState(() => ({ hasError: true }));
  }

  activate = async () => {
    try {
      const { success } = await this.props.addCode(this.state.code);
      if (!success) {
        throw new Error(`Activate code request failed. Code: ${this.state.code}`);
      }
    } catch (error) {
      this.showMessage();
      console.error(error);
      return Promise.reject(null);
    }
  };

  render() {
    return this.state.showInput ? (
      <Base>
        <InputContainer>
          <Input
            type="text"
            invalid={this.state.hasError}
            value={this.state.code}
            variant={Variant.Small}
            onChange={this.inputCode}
            onFocus={this.reset}
            elementRef={this.ref}
          />
          <Activate
            appearance={[Appearance.Secondary, Appearance.Full]}
            variant={Variant.Small}
            disabled={!this.state.code}
            onClick={this.activate}
          >
            {translate('/Vouchers/Activate')}
          </Activate>
        </InputContainer>
        {this.state.hasError && <Message>{this.props.errorMessage}</Message>}
      </Base>
    ) : (
      <Label onClick={this.showInput}>{this.props.title}</Label>
    );
  }
}

const Base = styled.div({
  flexBasis: pixelsToUnit(210),
  [minTinyMediaQuery]: {
    flexBasis: pixelsToUnit(250),
  },
  [minMediumMediaQuery]: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const InputContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Label = styled.div({
  borderBottom: {
    width: thick,
    style: 'solid',
    color: theme => theme.primaryColor,
  },
  color: tundora,
  cursor: 'pointer',
  fontSize: kappa,
  letterSpacing: pixelsToUnit(0.16),
  lineHeight: pixelsToUnit(15),
  margin: {
    top: pixelsToUnit(1),
    bottom: pixelsToUnit(9),
  },
  [minMediumMediaQuery]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

const Input = styled(SharedInput, {
  borderRightWidth: 0,
  borderRadius: 0,
  boxShadow: 'none',
  flex: {
    basis: pixelsToUnit(100),
    grow: 0.7,
  },
  minWidth: 0,
  paddingLeft: pixelsToUnit(8),
  ':focus': {
    borderRightWidth: 0,
  },
});

const Activate = styled(FeedbackButton, {
  fontSize: kappa,
  flex: {
    basis: pixelsToUnit(65),
    grow: 0.3,
  },
});

const Message = styled.div({
  color: redRibbon,
  font: {
    size: eta,
    style: 'italic',
  },
  letterSpacing: pixelsToUnit(0.9),
  marginTop: pixelsToUnit(5),
});
