import React from 'react';
import { LinksContainer } from '@avensia/scope';
import { styled, StyledProps, applyClassName } from '@glitz/react';
import H2 from 'Shared/Generic/h2';
import Check from 'Shared/Icon/Check-NewsLetter';
import { alpha, pixelsToUnit, thin, minMediumMediaQuery, whitelilac, small } from 'Shared/Style';
import Html, { CheckoutPageHtmlProvider } from 'Shared/Partials/PropertyTypes/Html';

type PropType = StyledProps & {
  subscribe: boolean;
  toggleSubscription: () => void;
  heading?: string;
  note?: Scope.XhtmlString;
  description?: Scope.XhtmlString;
};

class NewsLetter extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
    this.toggleSubscription = this.toggleSubscription.bind(this);
  }

  toggleSubscription() {
    this.props.toggleSubscription();
  }

  render() {
    return (
      <styled.Section css={this.props.compose()}>
        <Wrapper>
          {!!this.props.heading && <Header>{this.props.heading}</Header>}
          {!!this.props.description && (
            <CheckoutPageHtmlProvider value>
              <Html html={this.props.description.html} />
            </CheckoutPageHtmlProvider>
          )}
          <CheckBox>
            <Box onClick={this.toggleSubscription}>{this.props.subscribe && <Check />}</Box>
            {!!this.props.note && (
              <LinksContainer>
                <Note html={this.props.note.html} />
              </LinksContainer>
            )}
          </CheckBox>
        </Wrapper>
      </styled.Section>
    );
  }
}

export default styled(NewsLetter, {
  backgroundColor: whitelilac,
  padding: {
    x: pixelsToUnit(24),
    top: small,
    bottom: pixelsToUnit(24),
  },
  [minMediumMediaQuery]: {
    padding: {
      xy: pixelsToUnit(24),
    },
  },
});

const Header = styled(H2, {
  fontSize: alpha,
  lineHeight: 'normal',
  marginBottom: pixelsToUnit(15),
  textAlign: 'center',
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(30),
  },
});

const Wrapper = styled.div({
  margin: { x: 'auto', y: 0 },
  [minMediumMediaQuery]: {
    maxWidth: pixelsToUnit(528),
  },
});

const Note = styled(applyClassName(Html), {
  marginLeft: pixelsToUnit(12),
  marginTop: pixelsToUnit(-15),
  width: '95%',
});

const CheckBox = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  marginTop: pixelsToUnit(40),
  position: 'relative',
});

const Box = styled.div({
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: 'gray',
    },
  },
  cursor: 'pointer',
  height: pixelsToUnit(17),
  marginTop: pixelsToUnit(5),
  position: 'relative',
  width: pixelsToUnit(17),
});
