import React from 'react';
import { RequiredPropType as FullCartPropType, ConnectPropType as CartActionsPropType } from 'Checkout/FullCart';
import Row from './Row';

type PropType = Pick<FullCartPropType, Exclude<keyof FullCartPropType, 'tableHeadings'>> & CartActionsPropType;

export default function(props: PropType) {
  return (
    <React.Fragment>
      {props.cart.items.map(item => (
        <Row
          key= {`${item.code}|${item.isGift}`}
          item={item}
          currency={props.cart.currency}
          isLoading={props.isLoading}
          update={props.updateCartItemQuantity}
          delete={props.removeCartItem}
        />
      ))}
    </React.Fragment>
  );
}
